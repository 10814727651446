import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Dropdown, Switch } from 'antd';
import clsx from 'clsx';
import { filter, find, forEach, map, nth, slice } from 'lodash';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../AppContext';
import {
  AddButton,
  EditButton,
  KebabMenu,
  Timezone,
} from '../../../assets/svg';
import {
  ACCESS_TYPE,
  ALLOWED_ACTION_KEYS,
  BREAKPOINTS,
  DEFAULTDATEFORMAT,
  DEFAULT_PAGE_SIZE,
  GA_EVENT,
  GA_LABEL,
  PROJECT_ROLES,
  ROUTES,
  TAB_KEYS,
} from '../../../common/constants';
import { Event } from '../../../common/trackEvents';
import useRouter from '../../../common/useRouter';
import { removeHistoryStateData, titleCase } from '../../../common/utils';
import CanPerform from '../../../components/CanPerform';
import CommonCard from '../../../components/CommonCard';
import CommonTable from '../../../components/CommonTable';
import CommonTooltip from '../../../components/CommonTooltip';
import HasAccess from '../../../components/HasAccess';
import InfiniteScrollHandler from '../../../components/InfiniteScrollHandler';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import { GET_PROFILE } from '../../users/graphql/Queries';
import ActivationModal from '../ActivationModal';
import { UPDATE_PROJECT_LIST } from '../graphql/Mutation';
import { GET_PROJECT_LIST } from '../graphql/Queries';

const ProjectList = () => {
  const { getCurrentUser, initializeAuth } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const { navigate, location } = useRouter();
  const navFilter = location?.state?.navFilter;
  const navPagination = location?.state?.navPagination;
  const initialProjectFilter = {
    skip: 0,
    limit: 10,
    sortBy: { field: 'createdAt', order: 'DESC' },
  };
  const initialPaginationValue = {
    total: 0,
    current: 1,
  };
  const [paginationProp, setPaginationProp] = useState(
    navPagination || initialPaginationValue,
  );
  const [projectFilter, setProjectFilter] = useState(
    navFilter || initialProjectFilter,
  );
  const [showStatusModal, setStatusModal] = useState(false);
  const [title, setTitle] = useState('');
  const [mutationId, setMutationId] = useState('');
  const [isActive, setIsActive] = useState(false);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [projectListData, setProjectListData] = useState();
  const hasInspectionAccess = HasAccess({ type: ACCESS_TYPE.INSPECTION });
  const [sortedInfo, setSortedInfo] = useState({});
  const [totalProjectCount, setTotalProjectCount] = useState(0);
  const [activeProjectCount, setActiveProjectCount] = useState(0);

  const { data: userData } = useQuery(GET_PROFILE, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      initializeAuth('', '', res?.getLoggedInUser);
    },
  });

  useEffect(() => {
    if (navFilter) {
      setProjectFilter(navFilter);
    }
    if (navPagination) {
      setPaginationProp(navPagination);
    }
  }, [navFilter, navPagination]);

  const [fetchProjectData, { loading }] = useLazyQuery(GET_PROJECT_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const data = res?.projectList?.data;
      const pagination = {
        ...paginationProp,
        total: res?.projectList?.total,
      };
      setTotalProjectCount(res?.projectList?.projectLimit);
      setActiveProjectCount(res?.projectList?.activeProjectCount);
      if (scrollFlag) {
        const datalist = [...projectListData, ...data];
        setProjectListData(datalist);
        setScrollFlag(false);
      } else {
        const datalist = [...data];
        setProjectListData(datalist);
      }
      setHasMore(!!data?.length);
      setPaginationProp(pagination);
    },
    onError() {},
  });

  useEffect(() => {
    fetchProjectData({ variables: { filter: projectFilter } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectFilter]);

  const [updateProject] = useMutation(UPDATE_PROJECT_LIST, {
    onError() {},
    onCompleted() {
      Event(GA_EVENT.EDIT_PROJECT, {
        label: GA_LABEL.EDIT_PROJECT,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: mutationId,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
      });
      setStatusModal(false);
      fetchProjectData({ variables: { filter: projectFilter } });
    },
  });

  const handleTableChange = (pagination, paginationFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });
    setSortedInfo(sorter);
    if (sorter?.column) {
      setProjectFilter({
        ...projectFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: {
          field: sorter.columnKey,
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC',
        },
      });
      fetchProjectData({
        variables: {
          filter: {
            ...projectFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: {
              field: sorter.columnKey,
              order: sorter.order === 'ascend' ? 'ASC' : 'DESC',
            },
          },
        },
      });
    } else {
      setProjectFilter({
        ...projectFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: { field: 'createdAt', order: 'DESC' },
      });

      fetchProjectData({
        variables: {
          filter: {
            ...projectFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: { field: 'createdAt', order: 'DESC' },
          },
        },
      });
    }
  };

  const handleEditModal = (e, id) => {
    e?.stopPropagation?.();
    navigate(`${ROUTES.PROJECTS}/${id}/edit`);
  };
  const getAssignedUsers = (record) => {
    const fetchName = [];
    const activeUsers = filter(
      record?.projectUsers,
      (user) => user?.user?.isDisabled === false,
    );
    if (activeUsers?.length !== 0) {
      forEach(activeUsers, (o) => {
        fetchName.push(o?.user?.name);
      });
    }
    const selectedName = nth(fetchName, 0);
    if (fetchName?.length === 1) {
      return (
        <CommonTooltip
          title={selectedName}
          placement={isDesktopViewport ? 'right' : 'top'}
        >
          {selectedName}
        </CommonTooltip>
      );
    }
    if (fetchName?.length > 1) {
      return (
        <CommonTooltip
          title={slice(fetchName, 1, fetchName?.length).join(', ')}
          placement={isDesktopViewport ? 'right' : 'top'}
          getPopupContainer={() => {
            // eslint-disable-next-line no-undef
            document.querySelector('.pointer');
          }}
        >
          {`${selectedName} and +${fetchName?.length - 1}`}
        </CommonTooltip>
      );
    }

    return '-';
  };

  const getMyRole = (record) => {
    let fetchRole;
    if (record?.projectUsers?.length !== 0) {
      const findRoles = find(record?.projectUsers, (o) => {
        if (o.userId === userData?.getLoggedInUser?.id) return o.userId;
      });
      fetchRole = findRoles;
    }

    return (
      (fetchRole?.roles && titleCase(PROJECT_ROLES[fetchRole?.roles])) || '-'
    );
  };
  const handleSwitch = (e, record) => {
    e?.stopPropagation?.();
    if (record?.isActive === false) setTitle('Activate');
    else setTitle('Deactivate');
    setMutationId(record?.id);
    setIsActive(record?.isActive);
    setStatusModal(true);
  };
  const columns = [
    {
      title: '#',
      key: 'id',
      render: (text, record, index) => {
        return <div>{projectFilter?.skip + index + 1}</div>;
      },
    },
    {
      title: 'PROJECT NAME',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
    },
    {
      title: 'UNIQUE CODE',
      dataIndex: 'uniqueCode',
      key: 'uniqueCode',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'uniqueCode' && sortedInfo?.order,
    },

    {
      title: 'CLIENT NAME',
      dataIndex: 'clientName',
      key: 'clientName',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'clientName' && sortedInfo?.order,
    },
    {
      title: 'ASSIGNED USERS',
      key: 'assignedUsers',
      render: (users, record) => getAssignedUsers(record),
    },
    {
      title: 'UPDATED BY',
      key: 'UpdatedBy',
      render: (record) => {
        return record?.creator?.name;
      },
    },

    {
      title: 'UPDATED ON',
      key: 'updatedAt',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'updatedAt' && sortedInfo?.order,
      render: (updatedOn, record) => {
        return record?.updatedAt ? (
          <div>
            {moment(`${record?.updatedAt}`)
              ?.tz(record?.timeZone)
              ?.format(DEFAULTDATEFORMAT)}
            <div className="d-flex align-center timezone-div">
              <Timezone title="timeZone" />
              {record?.timeZone}
            </div>
          </div>
        ) : (
          '-'
        );
      },
    },
    {
      title: 'MY ROLE',
      key: 'MyRole',
      render: (role, record) => getMyRole(record),
    },
    ...(CanPerform({ action: ALLOWED_ACTION_KEYS.EDIT_PROJECT })
      ? [
          {
            title: '',
            key: 'action',
            render: (action, record) => {
              return (
                <div className="d-flex align-center">
                  <CommonTooltip trigger="focus" title="Edit">
                    <Button
                      shape="round"
                      icon={<EditButton />}
                      className="edit-button pointer"
                      onClick={(e) => handleEditModal(e, record?.id)}
                    />
                  </CommonTooltip>
                  <CommonTooltip
                    trigger="focus"
                    title={record?.isActive ? 'Deactivate' : 'Activate'}
                  >
                    <Switch
                      onChange={(checkedvalue, e) => handleSwitch(e, record)}
                      checked={record?.isActive}
                    />
                  </CommonTooltip>
                </div>
              );
            },
          },
        ]
      : []),
  ];
  const onSearchChange = async (value) => {
    if (!value) {
      removeHistoryStateData(navigate, location, 'navFilter', 'search');
    }
    setProjectFilter({ ...projectFilter, skip: 0, search: value });
    setPaginationProp(initialPaginationValue);
    fetchProjectData({
      variables: { filter: { ...projectFilter, skip: 0, search: value } },
    });
  };
  const handleRowClick = (record) => {
    if (record?.id) {
      const redirectPath = hasInspectionAccess
        ? TAB_KEYS.EQC
        : TAB_KEYS.INSTRUCTION;
      navigate(`${ROUTES.PROJECTS}/${record?.id}/${redirectPath}`, {
        state: {
          navFilter: projectFilter,
          navPagination: paginationProp,
          currentPath: location?.pathname,
        },
      });
    }
  };
  const handleRefetch = () => {
    fetchProjectData({
      variables: {
        filter: {
          ...projectFilter,
          skip: projectListData?.length,
          limit: DEFAULT_PAGE_SIZE,
          sortBy: { field: 'createdAt', order: 'DESC' },
        },
      },
    });
  };

  return (
    <>
      {showStatusModal && (
        <ActivationModal
          showModal={showStatusModal}
          setShowModal={setStatusModal}
          title={title}
          isActive={isActive}
          setIsActive={setIsActive}
          updateStatus={updateProject}
          mutationId={mutationId}
          setMutationId={setMutationId}
          subtitle="Project"
        />
      )}
      <Portal portalId="add-search-projects">
        <SearchComponent
          id="search-container-id"
          className="search-component mr-10"
          getData={onSearchChange}
          defaultValue={navFilter?.search}
        />
        <CanPerform action={ALLOWED_ACTION_KEYS.ADD_PROJECT}>
          <Button
            shape="round"
            type="primary"
            icon={<AddButton />}
            className="d-flex"
            onClick={() => {
              navigate(ROUTES.ADD_PROJECTS);
            }}
          >
            {isDesktopViewport && 'Add'}
          </Button>
        </CanPerform>
      </Portal>
      <Portal portalId="sub-header">
        <h5 className={clsx('mt-5', !isDesktopViewport ? 'ml-16' : '')}>
          Active Projects: {activeProjectCount} out of {totalProjectCount}
        </h5>
      </Portal>
      <div className="pointer ">
        {isDesktopViewport ? (
          <CommonTable
            rowClassName={(record) => {
              if (record?.isActive === false) return 'deactivated-color';
            }}
            columns={columns}
            data={projectListData || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowKey={(obj) => obj?.key ?? obj?.id}
            loading={loading}
            onRow={(record) => {
              return {
                onClick: () => handleRowClick(record),
              };
            }}
          />
        ) : (
          <InfiniteScrollHandler
            scrollFlag={scrollFlag}
            loading={loading}
            refetchData={handleRefetch}
            setScrollFlag={setScrollFlag}
            hasMore={hasMore}
            dataLength={projectListData?.length}
            skeletonRows={columns?.length - 2}
          >
            {map(projectListData, (project, index) => {
              return (
                <CommonCard
                  key={project?.id}
                  onClick={() => handleRowClick(project)}
                >
                  <div className="common-card d-flex">
                    <div className="mr-5 fw-medium">{index + 1}.</div>
                    <div>
                      <div className="card-header fw-medium">
                        <span className="text-break">{project?.name}</span>
                      </div>
                      <div className="card-content text-secondary">
                        <br />
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Unique Code:</span>
                          <span className="text-break">
                            {project?.uniqueCode}
                          </span>
                        </div>
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Client Name:</span>
                          <span className="text-break">
                            {project?.clientName || '-'}
                          </span>
                        </div>
                        <div className="mb-15">
                          <span className="fw-medium mr-5">
                            Assigned users:
                          </span>
                          {getAssignedUsers(project)}
                        </div>
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Updated By:</span>
                          {project?.creator?.name}
                        </div>
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Updated On:</span>
                          {project?.updatedAt ? (
                            <div>
                              {moment(`${project?.updatedAt}`)
                                ?.tz(project?.timeZone)
                                ?.format(DEFAULTDATEFORMAT)}
                              <div className="d-flex align-center timezone-div">
                                <Timezone title="timeZone" />
                                {project?.timeZone}
                              </div>
                            </div>
                          ) : (
                            '-'
                          )}
                        </div>
                        <div>
                          <span className="fw-medium mr-5">My Role:</span>
                          {getMyRole(project)}
                        </div>
                      </div>
                    </div>
                    {CanPerform({
                      action: ALLOWED_ACTION_KEYS.EDIT_PROJECT,
                    }) && (
                      <span
                        className="d-flex align-center position-absolute project-action-btn"
                        onClick={(e) => e?.stopPropagation?.()}
                      >
                        <div className="d-flex align-center">
                          <CommonTooltip
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            title={
                              project?.isActive ? 'Deactivate' : 'Activate'
                            }
                            trigger="focus"
                          >
                            <Switch
                              onChange={(checkedValue, e) =>
                                handleSwitch(e, project)
                              }
                              size="small"
                              checked={project?.isActive}
                            />
                          </CommonTooltip>
                          <Dropdown
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            menu={{
                              items: [
                                {
                                  key: 'edit',
                                  label: (
                                    <div
                                      onClick={(e) =>
                                        handleEditModal(e, project?.id)
                                      }
                                    >
                                      Edit
                                    </div>
                                  ),
                                },
                              ],
                            }}
                            trigger={['click']}
                          >
                            <KebabMenu />
                          </Dropdown>
                        </div>
                      </span>
                    )}
                  </div>
                </CommonCard>
              );
            })}
          </InfiniteScrollHandler>
        )}
      </div>
    </>
  );
};

export default ProjectList;
