import { Button, Form, Modal } from 'antd';
import { clone, findIndex } from 'lodash';
import React, { useEffect } from 'react';
import { formValidatorRules, titleCase } from '../../common/utils';
import CommonDropdown from '../../components/CommonDropdown';
import { GET_USERS_DROPDOWN_LIST } from './graphql/Queries';

let approvers = [];
const ReplaceUserModal = ({
  showModal,
  setShowModal,
  approverData,
  userListData,
  setUserListData,
  actionType,
}) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (approverData?.newReplacedUser?.id) {
      form.setFieldsValue({
        replacedUser: approverData?.newReplacedUser?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approverData]);

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
  };

  const onSubmitFinish = async (formValues) => {
    const index = findIndex(userListData, { id: approverData?.id });
    if (formValues?.replacedUser) {
      const updatedUser = {
        ...userListData[index],
        newReplacedUser:
          approvers?.length > 0
            ? approvers?.[0]
            : approverData?.newReplacedUser?.[0],
      };
      const newUserData = clone(userListData);
      newUserData[index] = updatedUser;
      setUserListData([...newUserData]);
    }
    setShowModal(false);
    approvers = [];
  };
  const handleDropdownChange = (value, addExtraData) => {
    approvers.push(...addExtraData);
  };
  return (
    <Modal
      maskClosable={false}
      centered
      open={showModal}
      className="dialog"
      footer={null}
      closable={false}
      destroyOnClose
    >
      <h2>{titleCase(actionType)} User</h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmitFinish}
        className="stage-config"
      >
        <Form.Item
          className="mt-10"
          name="replacedUser"
          rules={[formValidatorRules.required]}
          label={
            <div className="d-flex justify-between width-percent-100">
              <span>User</span>
            </div>
          }
        >
          <CommonDropdown
            showSearch
            allowClear
            optionFilterProp="children"
            placeholder="Select User"
            query={GET_USERS_DROPDOWN_LIST}
            responsePath="userDropdownList.data"
            valuePath="id"
            labelPath="name"
            optionKey="user"
            fetchPolicy="network-only"
            onChange={handleDropdownChange}
            customOptions={[approverData?.newReplacedUser]}
            filterDataById={approverData?.projectUserId}
          />
        </Form.Item>
        <div className="form-buttons mt-15">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
          >
            {titleCase(actionType)}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ReplaceUserModal;
