import { CaretRightOutlined } from '@ant-design/icons';
import { Button, Checkbox, Typography } from 'antd';
import { filter, findIndex } from 'lodash';
import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useMedia } from 'react-use';
import { DragIcon } from '../../../../../../assets/svg';
import {
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
} from '../../../../../../common/constants';
import CanPerform from '../../../../../../components/CanPerform';

const CustomNode = ({
  checkedTreeNode,
  needActionButton,
  isProjectActive,
  isDisabledNodeSelected,
  onMoveNode,
  node = {},
  initialNodes,
  ...props
}) => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [{ isDragging }, drag] = useDrag({
    type: 'NODE',
    item: { id: node?.id, parent: node?.parent, data: node },
    collect: (monitor) => ({
      isDragging: monitor?.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: 'NODE',
    drop: (draggedNode) => {
      const filteredData = filter(
        initialNodes,
        (item) => item?.parent === node?.parent,
      );
      const index = findIndex(filteredData, (item) => item?.id === node?.id);
      onMoveNode(draggedNode?.id, node?.id, index + 1);
    },
    collect: (monitor) => ({
      isOver: monitor?.isOver(),
    }),
    //! commented for future use
    // hover: () => {
    //   if (!props?.isOpen) {
    //     props?.onToggle(node?.id);
    //   }
    // },
  });

  const indentClass = `indent-${props?.depth + 1}`;
  const canEdit = CanPerform({
    action: ALLOWED_ACTION_KEYS.EDIT_NOMENCLATURE,
    type: ALLOWED_ACTION_TYPE.BOTH,
  });

  const handleToggle = (e) => {
    e.stopPropagation();
    props?.onToggle(node?.id);
  };

  const handleSelect = () => props?.onSelect(node);

  return (
    <div
      className={`d-flex align-center custom-node ${indentClass} ${isDragging ? 'dragging' : ''} ${isOver ? 'drop-target' : ''}`}
      ref={(nodeRef) =>
        needActionButton && isDesktopViewport ? drag(drop(nodeRef)) : null
      }
    >
      {node?.data?.level !== 0 &&
        isProjectActive &&
        canEdit &&
        isDesktopViewport &&
        needActionButton && <DragIcon height={16} width={16} />}
      <div className={`expandIconWrapper ${props?.isOpen ? 'isOpen' : ''}`}>
        {node?.droppable && (
          <Button
            onClick={handleToggle}
            type="text"
            icon={<CaretRightOutlined height={10} width={10} />}
          />
        )}
      </div>
      <div>
        {isProjectActive &&
          canEdit &&
          isDesktopViewport &&
          (needActionButton ? node?.data?.level !== 0 : true) && (
            <Checkbox
              color="primary"
              size="small"
              checked={props?.isSelected}
              onClick={handleSelect}
              className="mr-10"
              disabled={
                needActionButton
                  ? (checkedTreeNode?.length > 0 &&
                      node?.parent !==
                        checkedTreeNode?.[0]?.data?.parentLevelId) ||
                    (checkedTreeNode?.length > 0 &&
                      (isDisabledNodeSelected
                        ? node?.data?.isActive
                        : !node?.data?.isActive))
                  : false
              }
            />
          )}
      </div>
      <div>
        <Typography variant="body2">{node?.text}</Typography>
      </div>
    </div>
  );
};

export default CustomNode;
