import { useLazyQuery } from '@apollo/client';
import { Image } from 'antd';
import { camelCase, filter, isArray, map, upperCase } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import {
  BREAKPOINTS,
  DATE_ISO_FORMAT,
  DEFAULT_PAGE_SIZE,
  DEFAULTDATEFORMAT,
  FALLBACK_PDF_URL,
  ROUTES,
  TAB_KEYS,
} from '../../../../../../../common/constants';
import useRouter from '../../../../../../../common/useRouter';
import CommonCard from '../../../../../../../components/CommonCard';
import CommonTable from '../../../../../../../components/CommonTable';
import InfiniteScrollHandler from '../../../../../../../components/InfiniteScrollHandler';
import { GET_REGISTER_ENTRIES } from '../../../../../graphql/Queries';

const EntriesTable = ({
  registerDetailsData,
  showListView,
  setIsDataEmpty,
}) => {
  const {
    navigate,
    location,
    params: { projectId, registerId },
  } = useRouter();
  const [tableColumns, setTableColumns] = useState([]);
  const [entriesData, setEntriesData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const initialRegisterEntryFilter = { skip: 0, limit: DEFAULT_PAGE_SIZE };
  const initialPaginationValue = {
    total: 0,
    current: 1,
  };
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [registerEntryFilter, setRegisterEntryFilter] = useState(
    initialRegisterEntryFilter,
  );

  const [getRegisterEntries, { loading }] = useLazyQuery(GET_REGISTER_ENTRIES, {
    fetchPolicy: 'cache-and-network',
    onError() {},
    onCompleted(res) {
      const data = res?.registerLogEntryByRegisterList?.data;
      const columns =
        res?.registerLogEntryByRegisterList?.registerLogEntryTitles;
      const pagination = {
        ...paginationProp,
        total: res?.registerLogEntryByRegisterList?.total,
      };
      setIsDataEmpty(!(res?.registerLogEntryByRegisterList?.total > 0));

      const formattedData = data?.map((item) => ({
        ...item,
        createdAt: moment(item?.createdAt).format(DEFAULTDATEFORMAT),
      }));

      setTableColumns(columns);

      if (scrollFlag) {
        setEntriesData([...entriesData, ...formattedData]);
        setScrollFlag(false);
      } else {
        setEntriesData(formattedData);
      }

      setHasMore(!!data?.length);
      setPaginationProp(pagination);
    },
  });

  useEffect(() => {
    getRegisterEntries({
      variables: { registerId, filter: registerEntryFilter },
    });
  }, [registerEntryFilter]);

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });
    setRegisterEntryFilter({
      ...registerEntryFilter,
      skip,
      limit: pagination.pageSize,
    });
    getRegisterEntries({
      variables: {
        registerId,
        filter: {
          ...registerEntryFilter,
          skip,
          limit: pagination.pageSize,
        },
      },
    });
  };

  const handleRefetch = () => {
    getRegisterEntries({
      variables: {
        registerId,
        filter: {
          ...registerEntryFilter,
          skip: entriesData?.length,
          limit: DEFAULT_PAGE_SIZE,
        },
      },
    });
  };

  const handleRowClick = (record) => {
    const registerLogId = record?.registerLogId;
    navigate(
      `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.REGISTER}/${registerId}/${registerLogId}`,
      {
        state: {
          registerDetailsData,
          currentPath: location?.pathname,
          showListView,
        },
      },
    );
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => (
        <div>{registerEntryFilter?.skip + index + 1}</div>
      ),
    },
    ...map(
      filter(tableColumns, (column) => column?.name !== 'registerLogId'),
      (column) => ({
        title: upperCase(column?.name),
        dataIndex: column?.name,
        index: camelCase(column?.name),
        render: (text) => {
          if (isArray(text)) {
            return map(text, ({ value }) => {
              return (
                <Image
                  wrapperClassName="mr-5 mb-5"
                  height={50}
                  width={50}
                  src={value}
                  fallback={FALLBACK_PDF_URL}
                />
              );
            });
          }
          if (moment(text, DATE_ISO_FORMAT, true).isValid()) {
            return moment(text)?.format(DEFAULTDATEFORMAT);
          }

          return text || '-';
        },
      }),
    ),
  ];

  return (
    <div>
      {isDesktopViewport ? (
        <CommonTable
          data={entriesData}
          columns={columns}
          loading={loading}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          onRow={(record) => {
            return {
              onClick: () => handleRowClick(record),
            };
          }}
          scroll={{ x: true }}
        />
      ) : (
        <div>
          <InfiniteScrollHandler
            scrollFlag={scrollFlag}
            refetchData={handleRefetch}
            setScrollFlag={setScrollFlag}
            hasMore={hasMore}
            loading={loading}
            dataLength={entriesData?.length}
          >
            {map(entriesData, (data, index) => {
              return (
                <CommonCard key={index} onClick={() => handleRowClick(data)}>
                  <div className="common-card d-flex">
                    <div className="mr-5 fw-medium">{index + 1}.</div>
                    <div>
                      <div className="card-content text-secondary">
                        <br />
                        {Object.entries(data)
                          ?.filter(([key]) => key !== 'registerLogId')
                          ?.map(([key, value]) => (
                            <div className="mb-15" key={key}>
                              <span className="fw-medium mr-5">
                                {upperCase(key)}:
                              </span>
                              <span>{value !== null ? value : '-'}</span>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </CommonCard>
              );
            })}
          </InfiniteScrollHandler>
        </div>
      )}
    </div>
  );
};

export default EntriesTable;
