import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Modal } from 'antd';
import React from 'react';
import useRouter from '../../../../../../../common/useRouter';
import { DELETE_REGISTER_DETAIL } from '../../../../../graphql/Mutation';
import { GET_REGISTER } from '../../../../../graphql/Queries';

const DeletePropertyModal = ({
  showModal,
  setShowModal,
  deleteSelectedProperty,
  setDeleteSelectedProperty,
  setPropertyDetails,
  setTableColumns,
  createRegisterData,
  propertyDetails,
}) => {
  const {
    params: { registerId },
  } = useRouter();

  const [getRegister] = useLazyQuery(GET_REGISTER, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted(response) {
      const registerDetails = response?.getRegister?.registerDetails;
      const registerFormFields = response?.getRegister?.registerFormFields;
      setPropertyDetails(registerDetails);
      setTableColumns(registerFormFields);
      setShowModal(false);
    },
  });

  const [deleteRegisterDetails] = useMutation(DELETE_REGISTER_DETAIL, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted() {
      setDeleteSelectedProperty();
      getRegister({ variables: { getRegisterId: registerId } });
    },
  });

  const handleOk = async () => {
    if (createRegisterData) {
      const updatedProperties = propertyDetails?.filter(
        (prop) => prop.tempId !== deleteSelectedProperty?.tempId,
      );
      setPropertyDetails(updatedProperties);
      setShowModal(false);
      setDeleteSelectedProperty();
    } else {
      await deleteRegisterDetails({
        variables: {
          deleteRegisterDetailId: deleteSelectedProperty?.id,
        },
      });
    }
  };

  const handleCancel = () => {
    setDeleteSelectedProperty();
    setShowModal(false);
  };

  return (
    <div>
      <Modal
        maskClosable={false}
        centered
        closable={false}
        footer={null}
        open={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <h2 className="mb-15">Delete Property</h2>
        <p>Are you sure you want to delete ?</p>
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            No, Cancel
          </Button>
          <Button
            shape="round"
            className="save-button"
            onClick={handleOk}
            type="primary"
          >
            Yes, Delete
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default DeletePropertyModal;
