import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Dropdown } from 'antd';
import clsx from 'clsx';
import { forEach, map, nth, slice } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../AppContext';
import {
  AddButton,
  DeleteButton,
  EditButton,
  KebabMenu,
} from '../../../../../../assets/svg';
import {
  ACCESS_TYPE,
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  DEFAULT_PAGE_SIZE,
  GA_EVENT,
  GA_LABEL,
  ROUTES,
  TAB_KEYS,
} from '../../../../../../common/constants';
import { Event } from '../../../../../../common/trackEvents';
import useRouter from '../../../../../../common/useRouter';
import CanPerform from '../../../../../../components/CanPerform';
import CommonCard from '../../../../../../components/CommonCard';
import CommonTable from '../../../../../../components/CommonTable';
import CommonTooltip from '../../../../../../components/CommonTooltip';
import HasAccess from '../../../../../../components/HasAccess';
import InfiniteScrollHandler from '../../../../../../components/InfiniteScrollHandler';
import SearchComponent from '../../../../../../components/SearchComponent';
import { DELETE_PROJECT_AGENCY } from '../../../../graphql/Mutation';
import {
  GET_PROJECT_AGENCY_LIST,
  GET_UNASSIGN_AGENCY_LIST_COUNT,
} from '../../../../graphql/Queries';
import AddAgencyModal from './AddAgencyModal';
import DeleteProjectAgency from './DeleteProjectAgency';

const Agency = () => {
  const {
    navigate,
    params: { projectId },
  } = useRouter();
  const initialProjectAgencyFilter = {
    skip: 0,
    limit: 10,
    projectId,
    sortBy: { field: 'createdAt', order: 'DESC' },
  };
  const initialPaginationValue = {
    total: 0,
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  };
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [projectAgencyFilter, setProjectAgencyFilter] = useState(
    initialProjectAgencyFilter,
  );
  const [showModal, setShowModal] = useState(false);
  const [agencyData, setAgencyData] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [title, setTitle] = useState('');
  const [name, setName] = useState('');
  const [mutationId, setMutationId] = useState('');
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [agencyListData, setAgencyListData] = useState();
  const [totalAgencyListData, setTotalAgencyListData] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const { data: unAssignAgencyData } = useQuery(
    GET_UNASSIGN_AGENCY_LIST_COUNT,
    {
      variables: { filter: projectAgencyFilter },
      fetchPolicy: 'network-only',
    },
  );
  const [fetchProjectAgency, { loading }] = useLazyQuery(
    GET_PROJECT_AGENCY_LIST,
    {
      variables: {
        filter: projectAgencyFilter,
      },
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        setTotalAgencyListData(res?.projectAgencyList?.total);
        const data = res?.projectAgencyList?.data;
        const pagination = {
          ...paginationProp,
          total: res?.projectAgencyList?.total,
        };
        if (scrollFlag) {
          const datalist = [...agencyListData, ...data];
          setAgencyListData(datalist);
          setScrollFlag(false);
        } else {
          const datalist = [...data];
          setAgencyListData(datalist);
        }
        setHasMore(!!data?.length);
        setPaginationProp(pagination);
      },
      onError() {},
    },
  );

  useEffect(() => {
    fetchProjectAgency({ variables: { filter: projectAgencyFilter } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchAgencyDetails = () => {
    fetchProjectAgency({
      variables: { filter: { ...projectAgencyFilter } },
    });
  };

  const handleRefetchAfterDelete = () => {
    const newSkip =
      agencyListData?.length === 1
        ? Math.max(0, projectAgencyFilter?.skip - paginationProp?.pageSize)
        : projectAgencyFilter?.skip;
    setProjectAgencyFilter({
      ...projectAgencyFilter,
      skip: newSkip,
    });
    fetchProjectAgency({
      variables: {
        filter: {
          ...projectAgencyFilter,
          skip: newSkip,
        },
      },
    });
  };

  const [deleteProjectAgency] = useMutation(DELETE_PROJECT_AGENCY, {
    onError() {},
    onCompleted() {
      Event(GA_EVENT.DELETE_PROJECT_AGENCY, {
        label: GA_LABEL.DELETE_PROJECT_AGENCY,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: projectId,
        project_agency_id: mutationId,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
      });
      handleRefetchAfterDelete();
    },
  });

  const handleTableChange = (pagination, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });

    if (sorter?.column) {
      setProjectAgencyFilter({
        ...projectAgencyFilter,
        skip,
        limit: pagination.pageSize,
      });
      fetchProjectAgency({
        variables: {
          filter: {
            ...projectAgencyFilter,
            skip,
            limit: pagination.pageSize,
          },
        },
      });
    } else {
      setProjectAgencyFilter({
        ...projectAgencyFilter,
        skip,
        limit: pagination.pageSize,
      });

      fetchProjectAgency({
        variables: {
          filter: {
            ...projectAgencyFilter,
            skip,
            limit: pagination.pageSize,
          },
        },
      });
    }
  };

  const handleAddEdit = () => {
    setShowModal(true);
  };
  const getContactNames = (record) => {
    const fetchName = [];
    if (record?.projectAgencyContacts?.length !== 0) {
      forEach(record?.projectAgencyContacts, (o) => {
        fetchName.push(o?.contact?.name);
      });
    }
    const selectedName = nth(fetchName, 0);
    if (fetchName?.length === 1) {
      return (
        <CommonTooltip
          title={selectedName}
          placement={isDesktopViewport ? 'right' : 'top'}
        >
          {selectedName}
        </CommonTooltip>
      );
    }
    if (fetchName?.length > 1) {
      return (
        <CommonTooltip
          title={slice(fetchName, 1, fetchName?.length).join(', ')}
          placement={isDesktopViewport ? 'right' : 'top'}
        >
          {`${selectedName} and +${fetchName?.length - 1}`}
        </CommonTooltip>
      );
    }

    return '-';
  };

  const getAssignedEqcType = (record) => {
    const fetchName = [];
    if (record?.projectAgencyEqcs?.length !== 0) {
      forEach(record?.projectAgencyEqcs, (o) => {
        fetchName.push(o?.name);
      });
    }
    const selectedName = nth(fetchName, 0);
    if (fetchName?.length === 1) {
      return (
        <CommonTooltip
          title={selectedName}
          placement={isDesktopViewport ? 'right' : 'top'}
        >
          {selectedName}
        </CommonTooltip>
      );
    }
    if (fetchName?.length > 1) {
      return (
        <CommonTooltip
          title={slice(fetchName, 1, fetchName?.length).join(', ')}
          placement={isDesktopViewport ? 'right' : 'top'}
        >
          {`${selectedName} and +${fetchName?.length - 1}`}
        </CommonTooltip>
      );
    }

    return '-';
  };

  const getActionButtons = (record) => {
    const handleEdit = () => {
      setAgencyData(record);
      handleAddEdit();
    };
    const handleDelete = () => {
      setTitle('Project Agency');
      setName(record?.agency?.name);
      setMutationId(record?.id);
      setDeleteModal(true);
    };
    return (
      <CanPerform
        action={ALLOWED_ACTION_KEYS.EDIT_PROJECT_AGENCIES}
        type={ALLOWED_ACTION_TYPE.BOTH}
      >
        {isDesktopViewport ? (
          <div className="d-flex align-center">
            <CommonTooltip trigger="focus" title="Edit">
              <Button
                shape="round"
                className="b-0"
                icon={<EditButton />}
                onClick={handleEdit}
              />
            </CommonTooltip>
            <CommonTooltip trigger="focus" title="Delete">
              <Button
                shape="round"
                className="b-0"
                icon={<DeleteButton />}
                onClick={handleDelete}
              />
            </CommonTooltip>
          </div>
        ) : (
          <Dropdown
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            menu={{
              items: [
                {
                  key: 'Edit',
                  label: <span onClick={handleEdit}>Edit</span>,
                },
                {
                  type: 'divider',
                },
                {
                  key: 'Delete',
                  label: (
                    <span className="text-danger" onClick={handleDelete}>
                      Delete
                    </span>
                  ),
                },
              ],
            }}
            trigger={['click']}
          >
            <KebabMenu />
          </Dropdown>
        )}
      </CanPerform>
    );
  };

  const columns = [
    {
      title: '#',
      key: 'id',
      render: (text, record, index) => {
        return <div>{projectAgencyFilter?.skip + index + 1}</div>;
      },
    },
    {
      title: 'NAME',
      key: 'name',
      render: (record) => {
        return record?.agency?.name;
      },
    },
    {
      title: 'TYPE',
      key: 'type',
      render: (record) => {
        return record?.agency?.type;
      },
    },
    {
      title: 'CONTACT NAME',
      key: 'contactName',
      render: (contact, record) => getContactNames(record),
    },
    ...(HasAccess({ type: ACCESS_TYPE.INSPECTION })
      ? [
          {
            title: 'ASSIGNED CHECKLIST',
            key: 'assignedEqc',
            render: (text, record) => getAssignedEqcType(record),
          },
        ]
      : []),
    {
      key: 'actions',
      align: 'right',
      render: (record) => getActionButtons(record),
    },
  ];

  const onSearchChange = async (value) => {
    setProjectAgencyFilter({ ...projectAgencyFilter, skip: 0, search: value });
    setPaginationProp(initialPaginationValue);
    fetchProjectAgency({
      variables: { filter: { ...projectAgencyFilter, skip: 0, search: value } },
    });
  };
  const handleRefetch = () => {
    fetchProjectAgency({
      variables: {
        filter: {
          ...projectAgencyFilter,
          skip: agencyListData?.length,
          limit: DEFAULT_PAGE_SIZE,
        },
      },
    });
  };

  return (
    <>
      {showModal && (
        <AddAgencyModal
          showModal={showModal}
          setShowModal={setShowModal}
          agencyData={agencyData}
          isUpdate={!!agencyData}
          setAgencyData={setAgencyData}
          refetchAgencyData={refetchAgencyDetails}
        />
      )}

      {deleteModal && (
        <DeleteProjectAgency
          showModal={deleteModal}
          setShowModal={setDeleteModal}
          title={title}
          name={name}
          deleteProjectAgency={deleteProjectAgency}
          mutationId={mutationId}
          setMutationId={setMutationId}
        />
      )}
      <div className="project-tab-details">
        <div
          className={`project-tab-details-header ${
            isDesktopViewport ? 'd-flex justify-between align-center' : ''
          }`}
        >
          <div>
            <h2 className="d-flex align-center">
              Agency Details
              {unAssignAgencyData?.unassignAgencyContactList?.total > 0 && (
                <Button
                  shape="round"
                  type="primary"
                  className={clsx(
                    'd-flex align-center',
                    !isDesktopViewport ? 'font-size-12 ml-10' : 'ml-16',
                  )}
                  onClick={() => {
                    navigate(
                      `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.AGENCIES}/unassigned`,
                    );
                  }}
                >
                  {`Click here to assign ${unAssignAgencyData?.unassignAgencyContactList?.total} ${
                    unAssignAgencyData?.unassignAgencyContactList?.total > 1
                      ? 'contacts'
                      : 'contact'
                  }`}
                </Button>
              )}
            </h2>
            <div className="mt-15 data-count">
              {totalAgencyListData}
              {totalAgencyListData <= 1 ? ` Agency` : ` Agencies`}
            </div>
          </div>
          <div className="d-flex align-center">
            <SearchComponent
              className={`search-component mr-10 ${
                !isDesktopViewport ? 'width-percent-100' : ''
              }`}
              getData={onSearchChange}
            />
            <CanPerform
              action={ALLOWED_ACTION_KEYS.EDIT_PROJECT_AGENCIES}
              type={ALLOWED_ACTION_TYPE.BOTH}
            >
              <Button
                shape="round"
                type="primary"
                className="d-flex align-center"
                icon={<AddButton width="25px" />}
                onClick={() => {
                  handleAddEdit();
                }}
              >
                {isDesktopViewport && 'Add'}
              </Button>
            </CanPerform>
          </div>
        </div>
        {isDesktopViewport ? (
          <CommonTable
            columns={columns}
            data={agencyListData || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowKey={(item) => item?.key ?? item?.id}
            loading={loading}
          />
        ) : (
          <InfiniteScrollHandler
            scrollFlag={scrollFlag}
            loading={loading}
            refetchData={handleRefetch}
            setScrollFlag={setScrollFlag}
            hasMore={hasMore}
            dataLength={agencyListData?.length}
            skeletonRows={columns?.length - 3}
          >
            {map(agencyListData, (user, index) => {
              return (
                <CommonCard key={user?.id}>
                  <div className="common-card d-flex">
                    <div className="mr-5 fw-medium">{index + 1}.</div>
                    <div>
                      <div className="card-header fw-medium">
                        <span className="text-break">{user?.agency?.name}</span>
                      </div>
                      <div className="card-content text-secondary">
                        <br />
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Type:</span>
                          {user?.agency?.type}
                        </div>
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Contact Name:</span>
                          {getContactNames(user)}
                        </div>
                        <div>
                          <span className="fw-medium mr-5">
                            Assigned Checklist:
                          </span>
                          {getAssignedEqcType(user)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <span className="d-flex position-absolute user-action-btn">
                    {getActionButtons(user)}
                  </span>
                </CommonCard>
              );
            })}
          </InfiniteScrollHandler>
        )}
      </div>
    </>
  );
};

export default Agency;
