import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_PROJECT_LIST = gql`
  mutation updateProject($id: ID!, $data: UpdateProjectInput!) {
    updateProject(id: $id, data: $data) {
      status
      message
    }
  }
`;

export const CREATE_PROJECT_AGENCY = gql`
  mutation createProjectAgency($data: CreateProjectAgencyInput!) {
    createProjectAgency(data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_PROJECT_AGENCY = gql`
  mutation updateProjectAgency($id: ID!, $data: UpdateProjectAgencyInput!) {
    updateProjectAgency(id: $id, data: $data) {
      status
      message
    }
  }
`;
export const ASSIGN_PROJECT_AGENCY_CONTACT = gql`
  mutation assignProjectAgencyContact($data: AssignAgencyContactInput!) {
    assignProjectAgencyContact(data: $data) {
      status
      message
    }
  }
`;

export const DELETE_PROJECT_AGENCY = gql`
  mutation deleteProjectAgency($id: ID!) {
    deleteProjectAgency(id: $id) {
      status
      message
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation createProject($data: CreateProjectInput!) {
    createProject(data: $data) {
      id
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation updateProject($data: UpdateProjectInput!, $id: ID!) {
    updateProject(data: $data, id: $id) {
      status
      message
    }
  }
`;

export const ONBOARD_PROJECT_USERS = gql`
  mutation onboardProjectUsers($data: [CreateProjectUserInput!]) {
    onboardProjectUsers(data: $data) {
      status
      message
    }
  }
`;

export const ONBOARD_PROJECT_AGENCIES = gql`
  mutation onboardProjectAgencies($data: [CreateProjectAgencyInput!]!) {
    onboardProjectAgencies(data: $data) {
      status
      message
    }
  }
`;

export const ONBOARD_PROJECT_EQC_TYPES = gql`
  mutation onboardProjectEqcTypes($data: [OnboardProjectEqcTypeInput!]!) {
    onboardProjectEqcTypes(data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_PROJECT_EQC_TYPE = gql`
  mutation updateProjectEqcType($data: UpdateProjectEqcTypeInput!, $id: ID!) {
    updateProjectEqcType(data: $data, id: $id) {
      status
      message
    }
  }
`;
export const DUPLICATE_PROJECT_EQC_TYPE = gql`
  mutation duplicateProjectEqcType($data: DuplicateProjectEqcTypeInput!) {
    duplicateProjectEqcType(data: $data) {
      status
      message
    }
  }
`;
export const DUPLICATE_PROJECT_EQC_TYPE_STAGE = gql`
  mutation duplicateProjectEqcTypeStage(
    $data: DuplicateProjectEqcTypeStageInput!
  ) {
    duplicateProjectEqcTypeStage(data: $data) {
      status
      message
    }
  }
`;

export const ADD_PROJECT_EQC_TYPE = gql`
  mutation createProjectEqcType($data: CreateProjectEqcTypeInput!) {
    createProjectEqcType(data: $data) {
      status
      message
    }
  }
`;

export const RENAME_PROJECT_EQC = gql`
  mutation renameEqc($eqcName: String!, $id: ID!) {
    renameEqc(eqcName: $eqcName, id: $id) {
      status
      message
    }
  }
`;
export const CREATE_PROJECT_USER = gql`
  mutation createProjectUser($data: CreateProjectUserInput!) {
    createProjectUser(data: $data) {
      status
      message
    }
  }
`;

export const ADD_AUDITOR_COMMENT = gql`
  mutation addAuditorComment($data: AddAuditorCommentInput!) {
    addAuditorComment(data: $data) {
      status
      message
    }
  }
`;
export const CREATE_PROJECT_EQC_STAGE = gql`
  mutation createProjectEqcTypeStage($data: CreateProjectEqcTypeStageInput!) {
    createProjectEqcTypeStage(data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_PROJECT_EQC_STAGE = gql`
  mutation updateProjectEqcTypeStage(
    $id: ID!
    $data: UpdateProjectEqcTypeStageInput!
  ) {
    updateProjectEqcTypeStage(id: $id, data: $data) {
      status
      message
    }
  }
`;
export const UPDATE_PROJECT_EQC_STAGE_APPROVERS = gql`
  mutation updateProjectEqcTypeStageApprovers(
    $id: ID!
    $data: UpdateProjectEqcTypeStageApproversInput!
  ) {
    updateProjectEqcTypeStageApprovers(id: $id, data: $data) {
      status
      message
    }
  }
`;

export const CREATE_PROJECT_EQC_STAGE_ITEM = gql`
  mutation createProjectEqcTypeStageItem(
    $data: CreateProjectEqcTypeStageItemInput!
  ) {
    createProjectEqcTypeStageItem(data: $data) {
      status
      message
    }
  }
`;

export const CREATE_PROJECT_RFI_POINT = gql`
  mutation createProjectRFIPoint($data: CreateProjectRFIPointInput!) {
    createProjectRFIPoint(data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_PROJECT_RFI_POINT = gql`
  mutation updateProjectRFIPoint($id: ID!, $data: UpdateProjectRFIPointInput!) {
    updateProjectRFIPoint(id: $id, data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_PROJECT_EQC_STAGE_ITEM = gql`
  mutation updateProjectEqcTypeStageItem(
    $id: ID!
    $data: UpdateProjectEqcTypeStageItemInput!
  ) {
    updateProjectEqcTypeStageItem(id: $id, data: $data) {
      status
      message
    }
  }
`;
export const UPDATE_PROJECT_USER = gql`
  mutation updateProjectUser($id: ID!, $data: UpdateProjectUserInput!) {
    updateProjectUser(id: $id, data: $data) {
      status
      message
    }
  }
`;

export const DELETE_PROJECT_STAGE = gql`
  mutation deleteProjectEqcTypeStage($id: ID!) {
    deleteProjectEqcTypeStage(id: $id) {
      status
      message
    }
  }
`;
export const DELETE_PROJECT_USER = gql`
  mutation deleteProjectUser($id: ID!) {
    deleteProjectUser(id: $id) {
      status
      message
    }
  }
`;

export const DELETE_PROJECT_STAGE_ITEM = gql`
  mutation deleteProjectEqcTypeStageItem($id: ID!) {
    deleteProjectEqcTypeStageItem(id: $id) {
      status
      message
    }
  }
`;

export const DELETE_PROJECT_RFI_POINT = gql`
  mutation deleteProjectRFIPoint($id: ID!) {
    deleteProjectRFIPoint(id: $id) {
      status
      message
    }
  }
`;

export const REORDER_PROJECT_STAGE = gql`
  mutation reOrderProjectEqcTypeStage($data: ReOrderProjectEqcTypeStageInput!) {
    reOrderProjectEqcTypeStage(data: $data) {
      status
      message
    }
  }
`;

export const REORDER_PROJECT_STAGE_ITEM = gql`
  mutation reOrderProjectEqcTypeStageItem(
    $data: ReOrderProjectEqcTypeStageItemInput!
  ) {
    reOrderProjectEqcTypeStageItem(data: $data) {
      status
      message
    }
  }
`;

export const REORDER_PROJECT_RFI_POINT = gql`
  mutation reOrderProjectRFIPoint($data: ReOrderProjectRFIPointInput!) {
    reOrderProjectRFIPoint(data: $data) {
      status
      message
    }
  }
`;

export const PUBLISH_EQC_TYPE = gql`
  mutation publishProjectEqcType($data: PublishProjectEqcTypeInput!, $id: ID!) {
    publishProjectEqcType(data: $data, id: $id) {
      status
      message
      archivedProjectEqcTypeId
    }
  }
`;

export const PUBLISH_PROJECT_RFI = gql`
  mutation publishProjectRFI($id: ID!) {
    publishProjectRFI(id: $id) {
      status
      message
    }
  }
`;

export const RENAME_INSTRUCTION = gql`
  mutation renameInstruction($id: ID!, $name: String!) {
    renameInstruction(id: $id, name: $name) {
      status
      message
    }
  }
`;

export const MARK_QC_PASS = gql`
  mutation markQCPassFromWeb($data: MarkQCPassInput!) {
    markQCPassFromWeb(data: $data) {
      status
      message
    }
  }
`;

export const DRAFT_PROJECT_EQC_TYPE = gql`
  mutation draftProjectEqcType($data: DraftProjectEqcTypeInput!) {
    draftProjectEqcType(data: $data) {
      status
      draftProjectEqcTypeId
      draftCreatorInfo {
        createdAt
        name
        phoneNo
      }
    }
  }
`;

export const DISCARD_PROJECT_RFI_DRAFT = gql`
  mutation discardProjectRFIDraft($id: ID!) {
    discardProjectRFIDraft(id: $id) {
      status
      message
    }
  }
`;

export const PUBLISH_DRAFT_PROJECT_EQC_TYPE = gql`
  mutation publishDraftProjectEqcType($id: ID!) {
    publishDraftProjectEqcType(id: $id) {
      status
      message
    }
  }
`;

export const ARCHIVE_PROJECT_RFI = gql`
  mutation archiveProjectRFI($id: ID!) {
    archiveProjectRFI(id: $id) {
      status
      message
      projectRFIId
    }
  }
`;

export const DELETE_DRAFT_PROJECT_EQC_TYPE = gql`
  mutation deleteDraftProjectEqcType($id: ID!) {
    deleteDraftProjectEqcType(id: $id) {
      status
      message
    }
  }
`;

export const UPDATE_PROJECT_WEEKLY_TARGET = gql`
  mutation updateProjectWeeklyTarget($id: ID!, $target: Int) {
    updateProjectWeeklyTarget(id: $id, target: $target) {
      status
      message
    }
  }
`;

export const CREATE_PROJECT_NOMENCLATURE_LEVEL = gql`
  mutation createProjectNomenclatureLevel(
    $data: CreateProjectNomenclatureLevelInput!
  ) {
    createProjectNomenclatureLevel(data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_PROJECT_NOMENCLATURE_LEVEL = gql`
  mutation updateProjectNomenclatureLevel($id: ID!, $name: String!) {
    updateProjectNomenclatureLevel(id: $id, name: $name) {
      status
      message
    }
  }
`;

export const DELETE_PROJECT_NOMENCLATURE_LEVEL = gql`
  mutation deleteProjectNomenclatureLevel($levels: [ID!]!) {
    deleteProjectNomenclatureLevel(levels: $levels) {
      status
      message
    }
  }
`;

export const TOGGLE_PROJECT_NOMENCLATURE_LEVEL = gql`
  mutation toggleProjectNomenclatureLevel($levels: [ID!]!) {
    toggleProjectNomenclatureLevel(levels: $levels) {
      status
      message
    }
  }
`;

export const DUPLICATE_PROJECT_NOMENCLATURE_LEVEL = gql`
  mutation duplicateProjectNomenclatureLevel(
    $data: DuplicateProjectNomenclatureLevelInput!
  ) {
    duplicateProjectNomenclatureLevel(data: $data) {
      status
      message
    }
  }
`;

export const INDENT_PROJECT_NOMENCLATURE_LEVEL = gql`
  mutation indentProjectNomenclatureLevel(
    $data: IndentOrOutDentProjectNomenclatureLevelInput!
  ) {
    indentProjectNomenclatureLevel(data: $data) {
      status
      message
      indentedParentLevelIds
    }
  }
`;

export const OUTDENT_PROJECT_NOMENCLATURE_LEVEL = gql`
  mutation outdentProjectNomenclatureLevel(
    $data: IndentOrOutDentProjectNomenclatureLevelInput!
  ) {
    outdentProjectNomenclatureLevel(data: $data) {
      status
      message
      outdentedParentLevelIds
    }
  }
`;

export const PUBLISH_PROJECT_NOMENCLATURE = gql`
  mutation publishProjectNomenclature($projectId: ID!) {
    publishProjectNomenclature(projectId: $projectId) {
      status
      message
    }
  }
`;

export const IMPORT_PROJECT_NOMENCLATURE_CSV = gql`
  mutation importProjectNomenclatureCsv($key: String!, $projectId: ID!) {
    importProjectNomenclatureCsv(key: $key, projectId: $projectId) {
      status
      message
      data
    }
  }
`;

export const MOVE_PROJECT_NOMENCLATURE_LEVEL = gql`
  mutation moveProjectNomenclatureLevel(
    $data: MoveProjectNomenclatureLevelInput!
  ) {
    moveProjectNomenclatureLevel(data: $data) {
      status
      message
    }
  }
`;

export const CREATE_PROJECT_RFI_DRAFT = gql`
  mutation createProjectRFIDraft($data: CreateProjectRFIDraftInput!) {
    createProjectRFIDraft(data: $data) {
      status
      message
      projectRFIId
      draftCreatorInfo {
        createdAt
        name
        phoneNo
      }
    }
  }
`;

export const UPDATE_PROJECT_RFI_DRAFT = gql`
  mutation updateProjectRFIDraft($id: ID!, $data: UpdateProjectRFIDraftInput!) {
    updateProjectRFIDraft(id: $id, data: $data) {
      status
      message
    }
  }
`;
export const UPDATE_PROJECT_REPORT_CONFIG = gql`
  mutation updateProjectReportConfig(
    $id: ID!
    $data: UpdateProjectReportConfigInput!
  ) {
    updateProjectReportConfig(id: $id, data: $data) {
      status
      message
    }
  }
`;

export const CREATE_NEW_REGISTER = gql`
  mutation createRegister($data: CreateRegisterInput!) {
    createRegister(data: $data) {
      message
      status
    }
  }
`;

export const CREATE_NEW_SET_PROPERTY = gql`
  mutation createRegisterLog($registerId: ID!, $data: CreateRegisterLogInput!) {
    createRegisterLog(registerId: $registerId, data: $data) {
      message
      registerLogId
      status
    }
  }
`;

export const CREATE_REGISTER_DETAILS = gql`
  mutation createRegisterDetail($data: CreateRegisterDetailInput!) {
    createRegisterDetail(data: $data) {
      message
      status
    }
  }
`;

export const CREATE_REGISTER_TABLE_FIELDS = gql`
  mutation createRegisterLogFormFieldEntry(
    $registerLogId: ID!
    $data: CreateRegisterLogFormFieldEntryInput!
  ) {
    createRegisterLogFormFieldEntry(
      registerLogId: $registerLogId
      data: $data
    ) {
      id
      message
      status
    }
  }
`;

export const UPDATE_REGISTER = gql`
  mutation updateRegister($updateRegisterId: ID!, $data: UpdateRegisterInput!) {
    updateRegister(id: $updateRegisterId, data: $data) {
      message
      status
    }
  }
`;

export const CREATE_REGISTER_FORM_FIELD = gql`
  mutation CreateRegisterFormField($data: CreateRegisterFormFieldInput!) {
    createRegisterFormField(data: $data) {
      message
      status
    }
  }
`;

export const UPDATE_REGISTER_FORM_FIELD = gql`
  mutation UpdateRegisterFormField(
    $updateRegisterFormFieldId: ID!
    $data: UpdateRegisterFormFieldInput!
  ) {
    updateRegisterFormField(id: $updateRegisterFormFieldId, data: $data) {
      message
      status
    }
  }
`;

export const DELETE_REGISTER_FORM_FIELD = gql`
  mutation deleteRegisterFormField($id: ID!) {
    deleteRegisterFormField(id: $id) {
      message
      status
    }
  }
`;

export const UPDATE_REGISTER_FORM_FIELD_SEQUENCES = gql`
  mutation UpdateRegisterFormFieldSequences(
    $data: UpdateRegisterFormFieldSequencesInput!
  ) {
    updateRegisterFormFieldSequences(data: $data) {
      message
      status
    }
  }
`;

export const UPDATE_REGISTER_DETAIL = gql`
  mutation updateRegisterDetail(
    $updateRegisterDetailId: ID!
    $data: UpdateRegisterDetailInput!
  ) {
    updateRegisterDetail(id: $updateRegisterDetailId, data: $data) {
      message
      status
    }
  }
`;

export const UPDATE_REGISTER_LOG_DETAIL_ENTRY_LIST = gql`
  mutation updateRegisterLogDetailEntry(
    $registerLogId: ID!
    $entries: [RegisterLogDetailEntryInput!]!
  ) {
    updateRegisterLogDetailEntry(
      registerLogId: $registerLogId
      entries: $entries
    ) {
      message
      status
    }
  }
`;

export const UPDATE_REGISTER_LOG_DETAIL_ENTRY = gql`
  mutation updateRegisterLogFormFieldEntry(
    $updateRegisterLogFormFieldEntryId: ID!
    $data: UpdateRegisterLogFormFieldEntryInput!
  ) {
    updateRegisterLogFormFieldEntry(
      id: $updateRegisterLogFormFieldEntryId
      data: $data
    ) {
      message
      status
    }
  }
`;

export const DELETE_REGISTER_DETAIL = gql`
  mutation deleteRegisterDetail($deleteRegisterDetailId: ID!) {
    deleteRegisterDetail(id: $deleteRegisterDetailId) {
      message
      status
    }
  }
`;

export const DELETE_REGISTER_LOG_DETAIL_ENTRY = gql`
  mutation deleteRegisterLogFormFieldEntry(
    $deleteRegisterLogFormFieldEntryId: ID!
  ) {
    deleteRegisterLogFormFieldEntry(id: $deleteRegisterLogFormFieldEntryId) {
      message
      status
    }
  }
`;

export const REPLICATE_PROJECT_USER = gql`
  mutation ReplicateProjectUser($data: ReplicateProjectUserInput!) {
    replicateProjectUser(data: $data) {
      message
      status
    }
  }
`;
