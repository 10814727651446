import { useMutation } from '@apollo/client';
import { Button, Form, Modal } from 'antd';
import React, { useEffect } from 'react';
import { formValidatorRules, titleCase } from '../../../../../../common/utils';
import CommonDropdown from '../../../../../../components/CommonDropdown';
import { REPLICATE_PROJECT_USER } from '../../../../graphql/Mutation';
import { PROJECT_REPLICATE_USER_LIST } from '../../../../graphql/Queries';

const ReplaceReplicateProjectUserModal = ({
  showModal,
  setShowModal,
  approverData,
  type,
  userRecord,
  refetchUserDetails,
  setUserData,
}) => {
  const [replicateProjectUser, { loading }] = useMutation(
    REPLICATE_PROJECT_USER,
    {
      onError() {},
      onCompleted() {
        setShowModal(false);
        refetchUserDetails();
        setUserData();
      },
    },
  );

  const [form] = Form.useForm();
  useEffect(() => {
    if (approverData?.newReplacedUser?.id) {
      form.setFieldsValue({
        replacedUser: approverData?.newReplacedUser?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approverData]);

  const handleCancel = () => {
    setShowModal(false);
    setUserData();
    form.resetFields();
  };

  const onSubmitFinish = async (formValues) => {
    replicateProjectUser({
      variables: {
        data: {
          projectUserId: formValues?.replacedUser,
          id: userRecord?.id,
        },
      },
    });
    setShowModal(false);
  };

  return (
    <Modal
      maskClosable={false}
      centered
      open={showModal}
      className="dialog"
      footer={null}
      closable={false}
      destroyOnClose
    >
      <h2>{titleCase(type)} User</h2>
      Select a user to duplicate <b>{userRecord?.user?.name}</b>'s assignment
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmitFinish}
        className="stage-config"
      >
        <Form.Item
          className="mt-10"
          name="replacedUser"
          rules={[formValidatorRules.required]}
          label={
            <div className="d-flex justify-between width-percent-100">
              <span>User</span>
            </div>
          }
        >
          <CommonDropdown
            showSearch
            allowClear
            optionFilterProp="children"
            placeholder="Select User"
            query={PROJECT_REPLICATE_USER_LIST}
            variables={{
              filter: { projectUserId: Number(userRecord?.id) },
            }}
            fetchPolicy="network-only"
            responsePath="projectReplicateUserList.data"
            valuePath="id"
            labelPath="user.name"
            optionKey="user"
          />
        </Form.Item>
        <div className="form-buttons mt-15">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
            loading={loading}
          >
            {titleCase(type)}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ReplaceReplicateProjectUserModal;
