import { useMutation } from '@apollo/client';
import { Button, Form, Modal } from 'antd';
import React from 'react';
import { ROUTES, TAB_KEYS } from '../../../../../../../common/constants';
import useRouter from '../../../../../../../common/useRouter';
import { UPDATE_REGISTER_LOG_DETAIL_ENTRY_LIST } from '../../../../../graphql/Mutation';

const SaveRegisterLogModal = ({
  properties,
  propertyDetails,
  showModal,
  setShowModal,
  isEditable,
  setIsEditable,
  isEditableProperty,
  setIsEditableProperty,
}) => {
  const [form] = Form.useForm();
  const {
    navigate,
    params: { projectId, registerId, registerLogId },
  } = useRouter();

  const [updateRegisterLogDetail] = useMutation(
    UPDATE_REGISTER_LOG_DETAIL_ENTRY_LIST,
    {
      fetchPolicy: 'network-only',
      onError() {},
      onCompleted() {},
    },
  );

  const onFinish = async () => {
    const result = propertyDetails.map((item) => ({
      id: item.id,
      registerDetailId: item.registerDetailId,
      value: properties[item.index],
    }));

    if (isEditableProperty) {
      try {
        await updateRegisterLogDetail({
          variables: { registerLogId, entries: result },
        });
      } catch (error) {
        return error;
      }
    }
    if (isEditable) {
      navigate(
        `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.REGISTER}/${registerId}/${registerLogId}`,
      );
      setIsEditableProperty(false);
      setIsEditable(false);
      setShowModal(false);
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <div>
      <Modal
        open={showModal}
        maskClosable={false}
        form={form}
        centered
        onCancel={handleCancel}
        closable={false}
        footer={null}
        destroyOnClose
        onOk={onFinish}
      >
        <h2 className="mb-15">Register Log Details</h2>
        <p className="mb-15">Are you sure you update details!</p>
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            onClick={onFinish}
          >
            Save
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default SaveRegisterLogModal;
