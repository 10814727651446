import { useLazyQuery } from '@apollo/client';
import { camelCase, map } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import {
  BREAKPOINTS,
  DATE_ISO_FORMAT,
  DEFAULT_PAGE_SIZE,
  DEFAULTDATEFORMAT,
  REGISTER_STATUS_LABEL,
  ROUTES,
  TAB_KEYS,
} from '../../../../../../../common/constants';
import useRouter from '../../../../../../../common/useRouter';
import CommonCard from '../../../../../../../components/CommonCard';
import CommonTable from '../../../../../../../components/CommonTable';
import InfiniteScrollHandler from '../../../../../../../components/InfiniteScrollHandler';
import { GET_REGISTER_LOGS } from '../../../../../graphql/Queries';

const SetsTable = ({ registerDetailsData, showListView, setIsDataEmpty }) => {
  const {
    navigate,
    params: { projectId, registerId },
    location,
  } = useRouter();
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const initialLogsFilter = {
    skip: 0,
    limit: 10,
    status: REGISTER_STATUS_LABEL.LIVE,
  };
  const initialPaginationValue = {
    total: 0,
    current: 1,
  };
  const [setsData, setSetsData] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [registerLogsFilter, setRegisterLogsFilter] =
    useState(initialLogsFilter);
  const [paginationProps, setPaginationProps] = useState(
    initialPaginationValue,
  );

  const [getRegisterLogs, { loading }] = useLazyQuery(GET_REGISTER_LOGS, {
    fetchPolicy: 'cache-and-network',
    onCompleted(response) {
      const data = response?.registerLogDetailEntryByRegisterList?.data;
      const total = response?.registerLogDetailEntryByRegisterList?.total;
      const pagination = {
        ...paginationProps,
        total,
      };
      setIsDataEmpty(!(total > 0));
      const result = map(data, (item, index) => {
        const obj = Object.keys(item)?.reduce((acc, curr) => {
          const newKey = camelCase(curr);
          if (curr === 'createdAt' && item[curr]) {
            acc[newKey] = moment(item[curr])?.format(DEFAULTDATEFORMAT);
          } else {
            acc[newKey] = item[curr];
          }
          return acc;
        }, {});
        obj.index = registerLogsFilter.skip + index + 1;
        return obj;
      });

      if (scrollFlag) {
        setSetsData((prevData) => [...prevData, ...result]);
      } else {
        setSetsData(result);
      }

      setHasMore(setsData.length + result.length < total);
      setScrollFlag(false);
      setPaginationProps(pagination);
    },
    onError() {},
  });

  useEffect(() => {
    if (!registerDetailsData?.id) return;
    if (registerDetailsData !== undefined) {
      getRegisterLogs({
        variables: {
          registerId: registerDetailsData?.id,
          filter: registerLogsFilter,
        },
      });
    }
  }, [registerDetailsData]);

  const columns = [
    {
      title: '#',
      key: 'id',
      dataIndex: 'index',
      render: (text, record, index) => (
        <div>{registerLogsFilter?.skip + index + 1}</div>
      ),
    },
    ...map(registerDetailsData?.registerDetails, (data) => ({
      title: data?.isRequired ? (
        <div className="d-flex">
          <div className="mr-5">{data?.name?.toUpperCase()}</div>
          <div className="danger-text">*</div>
        </div>
      ) : (
        `${data?.name?.toUpperCase()}`
      ),
      isRequired: `${data?.isRequired}`,
      dataIndex: `${camelCase(data?.name)}`,
      index: `${camelCase(data?.name)}`,
      render: (text) => {
        if (moment(text, DATE_ISO_FORMAT, true).isValid()) {
          return moment(text)?.format(DEFAULTDATEFORMAT);
        }
        return <div>{text || '-'}</div>;
      },
    })),
    {
      title: 'CREATED BY',
      dataIndex: 'createdBy',
      index: 'createdBy',
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      index: 'createdAt',
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (pagination, paginationFilter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setPaginationProps({ ...paginationProps, ...pagination });
    setRegisterLogsFilter({
      ...registerLogsFilter,
      skip,
      limit: pagination?.pageSize,
    });
    getRegisterLogs({
      variables: {
        registerId: registerDetailsData?.id,
        filter: {
          ...registerLogsFilter,
          skip,
          limit: pagination.pageSize,
        },
      },
    });
  };

  const handleRowClick = (record) => {
    const registerLogId = record?.id;
    navigate(
      `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.REGISTER}/${registerId}/${registerLogId}`,
      {
        state: {
          registerDetailsData,
          currentPath: location?.pathname,
          showListView,
        },
      },
    );
  };

  const handleRefetch = () => {
    if (!loading && hasMore) {
      setScrollFlag(true);
      getRegisterLogs({
        variables: {
          registerId: registerDetailsData?.id,
          filter: {
            ...registerLogsFilter,
            skip: setsData?.length,
            limit: DEFAULT_PAGE_SIZE,
          },
        },
      });
    }
  };

  return (
    <div className="pointer">
      {isDesktopViewport ? (
        <CommonTable
          data={setsData || []}
          columns={columns}
          loading={loading}
          onChange={handleTableChange}
          paginationConfig={paginationProps}
          onRow={(record) => {
            return {
              onClick: () => handleRowClick(record),
            };
          }}
        />
      ) : (
        <InfiniteScrollHandler
          scrollFlag={scrollFlag}
          setScrollFlag={setScrollFlag}
          hasMore={hasMore}
          loading={loading}
          refetchData={handleRefetch}
          dataLength={setsData?.length}
          skeletonRows={columns?.length - 2}
        >
          <div>
            {map(setsData, (data, index) => {
              return (
                <CommonCard key={index} onClick={() => handleRowClick(data)}>
                  <div className="common-card d-flex">
                    <div className="mr-5 fw-medium">{index + 1}.</div>
                    <div>
                      <div className="card-content text-secondary">
                        <br />
                        {Object.entries(data)
                          ?.filter(([key]) => key !== 'index')
                          ?.map(([key, value]) => (
                            <div className="mb-15" key={key}>
                              <span className="fw-medium mr-5">{key}:</span>
                              <span>{value !== null ? value : '-'}</span>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </CommonCard>
              );
            })}
          </div>
        </InfiniteScrollHandler>
      )}
    </div>
  );
};

export default SetsTable;
