import { useLazyQuery } from '@apollo/client';
import { Button, Dropdown, message, Space } from 'antd';
import clsx from 'clsx';
import * as eva from 'eva-icons';
import { findIndex, includes, isNaN } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../../AppContext';
import {
  Export,
  ForwardArrow,
  ProjectIcon,
} from '../../../../../../../assets/svg';
import {
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  GA_EVENT,
  GA_LABEL,
  ROUTES,
  TAB_KEYS,
} from '../../../../../../../common/constants';
import { Event } from '../../../../../../../common/trackEvents';
import useRouter from '../../../../../../../common/useRouter';
import CanPerform from '../../../../../../../components/CanPerform';
import EllipsisText from '../../../../../../../components/EllipsisText';
import LoaderComponent from '../../../../../../../components/LoaderComponent';
import Error404 from '../../../../../../../Error404';
import history from '../../../../../../../historyData';
import { GET_PROJECT_EQC_TYPE } from '../../../../../graphql/Queries';
import AddCommentModal from '../../eqc/eqcDetails/AddCommentModal';
import EqcDetailContent from './EqcDetailContent';

const EqcTypeDetails = () => {
  const [stageData, setStageData] = useState();
  const [showAddCommentsModal, setShowAddCommentsModal] = useState(false);
  const {
    location,
    navigate,
    params: { eqcTypeId, projectId },
  } = useRouter();
  const navFilter = location?.state?.navFilter;
  const navPagination = location?.state?.navPagination;
  const eqcTypeFilter = location?.state?.eqcTypeFilter;
  const eqcTypePagination = location?.state?.eqcTypePagination;
  const {
    getToken,
    getCurrentUser,
    dispatch,
    getCurrentTenant,
    getTenantUser,
  } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const tenantId = getCurrentTenant() || getTenantUser()?.tenant?.id;

  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unlisten = history.listen((props) => {
      if (props.action === 'POP') {
        navigate(props.location.pathname, {
          state: {
            navFilter,
            navPagination,
            eqcTypeFilter,
            eqcTypePagination,
          },
        });
      }
    });
    return () => {
      if (unlisten) {
        unlisten();
      }
    };
  }, []);

  const [fetchDataLazyQuery, { data: eqcTypeData, refetch, loading }] =
    useLazyQuery(GET_PROJECT_EQC_TYPE, {
      fetchPolicy: 'network-only',
      variables: {
        id: eqcTypeId,
      },
      onCompleted: (res) => {
        const index = findIndex(
          res?.getProjectEqcType?.project?.projectUsers,
          (user) => Number(user?.userId) === Number(currentUser?.id),
        );
        if (index !== -1) {
          const loggedInUserRole =
            res?.getProjectEqcType?.project?.projectUsers?.[index]?.roles;
          dispatch({ type: 'SET_PROJECT_ROLE', data: loggedInUserRole });
        } else {
          dispatch({ type: 'SET_PROJECT_ROLE', data: '' });
        }
      },
    });
  const fetchEqcTypeData = () => {
    if (!isNaN(Number(projectId)) && !isNaN(Number(eqcTypeId))) {
      fetchDataLazyQuery({
        variables: {
          id: eqcTypeId,
        },
      });
    }
  };
  useEffect(() => {
    fetchEqcTypeData();
    return () => {
      dispatch({ type: 'SET_PROJECT_ROLE', data: '' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eqcTypeId, location?.pathname]);

  const fetchData = eqcTypeData?.getProjectEqcType;
  const renderDownloadPdfButton = () => {
    const token = getToken();
    const url = `${process.env.REACT_APP_REPORT_SERVER_URL}/api/v1/report/project-eqc-types/${eqcTypeId}/export?access_token=Bearer ${token}&tenantId=${tenantId}`;
    return (
      <Button
        type="ghost"
        className="export-button ant-btn-icon-only d-flex b-0 justify-center width-percent-100"
        target="_blank"
        href={
          (fetchData?.projectEqcTypeStageCount > 0 || stageData?.length > 0) &&
          url
        }
        onClick={() => {
          if (
            fetchData?.projectEqcTypeStageCount <= 0 &&
            stageData?.length <= 0
          ) {
            message.error('Data not found.');
          }
          Event(GA_EVENT.EXPORT_PROJECT_CHECKLIST, {
            label: GA_LABEL.EXPORT_PROJECT_CHECKLIST,
            // eslint-disable-next-line no-undef
            pathname: window?.location?.href,
            project_id: projectId,
            project_checklist_id: eqcTypeId,
            user_id: currentUser?.id,
            user_name: currentUser?.name,
            tenant_id: currentUser?.tenantUser?.tenant?.id,
            tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
          });
        }}
      >
        PDF
      </Button>
    );
  };
  const renderDownloadCsvButton = () => {
    const token = getToken();
    const url = `${process.env.REACT_APP_REPORT_SERVER_URL}/api/v1/export-csv/report/project-eqc-types/${eqcTypeId}/export?access_token=Bearer ${token}&tenantId=${tenantId}`;
    return (
      <Button
        type="ghost"
        className="export-button ant-btn-icon-only d-flex b-0 justify-center width-percent-100"
        target="_blank"
        href={
          (fetchData?.projectEqcTypeStageCount > 0 || stageData?.length > 0) &&
          url
        }
        onClick={() => {
          if (
            fetchData?.projectEqcTypeStageCount <= 0 &&
            stageData?.length <= 0
          ) {
            message.error('Data not found.');
          }
          Event(GA_EVENT.EXPORT_PROJECT_CHECKLIST_CSV, {
            label: GA_LABEL.EXPORT_PROJECT_CHECKLIST_CSV,
            // eslint-disable-next-line no-undef
            pathname: window?.location?.href,
            project_id: projectId,
            project_checklist_id: eqcTypeId,
            user_id: currentUser?.id,
            user_name: currentUser?.name,
            tenant_id: currentUser?.tenantUser?.tenant?.id,
            tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
          });
        }}
      >
        CSV
      </Button>
    );
  };
  if (loading) {
    return <LoaderComponent setHeight={80} />;
  }
  if (isNaN(Number(projectId)) || isNaN(Number(eqcTypeId))) {
    return <Error404 />;
  }
  return (
    <div className="eqc-type-details">
      <AddCommentModal
        setShowModal={setShowAddCommentsModal}
        showModal={showAddCommentsModal}
        sectionId={eqcTypeId}
        section="EQC_TYPE"
        refetch={() => {
          if (!fetchData?.isAudited) {
            fetchEqcTypeData();
          }
        }}
      />
      <div
        className={`project-details-title details-header  justify-between page-header ${
          isDesktopViewport ? 'd-flex' : ''
        }`}
      >
        <div
          className={`project-details-title-text d-flex ${
            isDesktopViewport ? '' : 'mb-15'
          }`}
        >
          <ProjectIcon
            onClick={() => {
              navigate(ROUTES.PROJECTS, {
                state: {
                  navFilter,
                  navPagination,
                },
              });
            }}
            className="pointer"
          />
          <ForwardArrow />
          <h1
            className="pointer mr-10 text-primary"
            onClick={() => {
              navigate(
                `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.CHECKLISTS}`,
                {
                  state: {
                    navFilter,
                    navPagination,
                    eqcTypeFilter,
                    eqcTypePagination,
                  },
                },
              );
            }}
          >
            {fetchData?.project?.name}
          </h1>
          <ForwardArrow />
          <div
            className={
              includes(location?.pathname?.split('/'), 'changelog') &&
              'pointer text-primary'
            }
          >
            <EllipsisText
              className={clsx(
                includes(location?.pathname?.split('/'), 'changelog')
                  ? 'font-size-22 fw-medium text-primary'
                  : 'h1-typography',
              )}
              onClick={() => {
                if (includes(location?.pathname?.split('/'), 'changelog')) {
                  navigate(
                    `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.CHECKLISTS}/${fetchData?.id}`,
                  );
                }
              }}
              text={fetchData?.name}
            />
          </div>
          {includes(location?.pathname?.split('/'), 'changelog') && (
            <>
              <ForwardArrow />
              <h1>Changelog</h1>
            </>
          )}
        </div>
        <Space className="d-flex justify-end">
          {!includes(location?.pathname?.split('/'), 'changelog') && (
            <Dropdown
              getPopupContainer={() =>
                // eslint-disable-next-line no-undef
                document.querySelector('.details-header')
              }
              menu={{
                items: [
                  {
                    key: 'pdf',
                    label: renderDownloadPdfButton(),
                  },
                  {
                    key: 'csv',
                    label: renderDownloadCsvButton(),
                  },
                ],
              }}
              trigger={['click']}
              placement="bottom"
            >
              <Button
                shape="round"
                icon={<Export height="26px" />}
                className="export-button d-flex b-0"
              >
                {isDesktopViewport && 'Export'}
              </Button>
            </Dropdown>
          )}
          <CanPerform
            action={ALLOWED_ACTION_KEYS.ADD_AUDITOR_COMMENTS}
            type={ALLOWED_ACTION_TYPE.BOTH}
          >
            <Button
              shape="round"
              className="b-0 comment-button"
              onClick={() => setShowAddCommentsModal(true)}
              disabled={!fetchData?.project?.isActive}
            >
              Add Comments
            </Button>
          </CanPerform>
        </Space>
      </div>
      <div className={clsx(isDesktopViewport ? 'mt-25' : 'p-14')}>
        <EqcDetailContent
          eqcTypeData={fetchData}
          onStageDataChange={setStageData}
          refetch={refetch}
          pageFilters={{
            navFilter,
            navPagination,
            eqcTypeFilter,
            eqcTypePagination,
          }}
        />
      </div>
    </div>
  );
};

export default EqcTypeDetails;
