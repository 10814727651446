import { useMutation } from '@apollo/client';
import { Button, Modal } from 'antd';
import React from 'react';
import {
  REGISTER_STATUS_LABEL,
  ROUTES,
  TAB_KEYS,
} from '../../../../../../common/constants';
import useRouter from '../../../../../../common/useRouter';
import { UPDATE_REGISTER } from '../../../../graphql/Mutation';

const ArchiveRegisterModal = ({ showModal, setShowModal, registerData }) => {
  const {
    navigate,
    params: { projectId, registerId },
  } = useRouter();

  const [updateRegister] = useMutation(UPDATE_REGISTER, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted() {},
  });

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleSave = async () => {
    if (registerData?.status === REGISTER_STATUS_LABEL.LIVE) {
      await updateRegister({
        variables: {
          updateRegisterId: registerId,
          data: {
            name: registerData?.name,
            status: REGISTER_STATUS_LABEL.ARCHIVED,
          },
        },
      });
      navigate(
        `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.REGISTER}/${registerId}`,
      );
    }

    if (registerData?.status === REGISTER_STATUS_LABEL.ARCHIVED) {
      await updateRegister({
        variables: {
          updateRegisterId: registerId,
          data: {
            name: registerData?.name,
            status: REGISTER_STATUS_LABEL.DRAFT,
          },
        },
      });
      navigate(
        `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.REGISTER}/${registerId}`,
      );
    }

    if (registerData?.status === REGISTER_STATUS_LABEL.DRAFT) {
      await updateRegister({
        variables: {
          updateRegisterId: registerId,
          data: {
            name: registerData?.name,
            status: REGISTER_STATUS_LABEL.LIVE,
          },
        },
      });
      navigate(
        `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.REGISTER}/${registerId}`,
      );
    }
    setShowModal(false);
  };

  return (
    <div>
      <Modal
        maskClosable={false}
        centered
        open={showModal}
        footer={null}
        closable={false}
      >
        <h2>CAUTION</h2>
        {registerData?.status === REGISTER_STATUS_LABEL.ARCHIVED && (
          <p>
            Register will be available in draft mode and has to make live. Are
            you sure to unarchive this register?
          </p>
        )}
        {registerData?.status === REGISTER_STATUS_LABEL.LIVE && (
          <p>This register will no longer be active. Are you sure?</p>
        )}
        {registerData?.status === REGISTER_STATUS_LABEL.DRAFT && (
          <p>
            Once saved, previous version will no longer be available. Are you
            sure?
          </p>
        )}
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            onClick={handleSave}
          >
            Yes
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ArchiveRegisterModal;
