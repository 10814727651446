import { useLazyQuery } from '@apollo/client';
import { Result, Tabs } from 'antd';
import { includes, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../../../AppContext';
import {
  ACCESS_TYPE,
  ROUTES,
  TAB_KEYS,
} from '../../../../../../common/constants';
import useRouter from '../../../../../../common/useRouter';
import EllipsisText from '../../../../../../components/EllipsisText';
import LoaderComponent from '../../../../../../components/LoaderComponent';
import { REGISTER_LIST } from '../../../../graphql/Queries';
import AddRegisterModal from './AddRegisterModal';
import RegisterDetails from './registerDetails/RegisterDetails';

const Register = () => {
  const {
    navigate,
    params: { projectId, registerId },
    location: { state },
  } = useRouter();

  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [showModal, setShowModal] = useState(false);
  const [registerData, setRegisterData] = useState([]);
  const [getAllRegisters, { loading }] = useLazyQuery(REGISTER_LIST, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted(res) {
      navigate(
        state?.currentPath ||
          `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.REGISTER}/${state?.registerId || registerId || res?.registerList?.data[0]?.id}`,
        {
          state: {
            currentPath: state?.currentPath,
          },
        },
      );
      const records = res?.registerList?.data;
      setRegisterData(records);
    },
  });

  useEffect(() => {
    if (
      includes(currentUser?.tenantUser?.tenant?.access, ACCESS_TYPE.REGISTER)
    ) {
      getAllRegisters({ variables: { projectId } });
    }
  }, []);

  const items = map(registerData, (register) => ({
    label: <EllipsisText text={register?.name} heightRows={2} />,
    key: register?.id,
    children: <RegisterDetails registerId={register?.id} />,
  }));

  if (loading) {
    return <LoaderComponent />;
  }
  return (
    <div className="register-details">
      {includes(
        currentUser?.tenantUser?.tenant?.access,
        ACCESS_TYPE.REGISTER,
      ) ? (
        <>
          <div>
            <Tabs
              onChange={(key) => {
                navigate(
                  `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.REGISTER}/${key}`,
                );
              }}
              activeKey={registerId}
              className="register-tabs"
              destroyInactiveTabPane
              items={items}
            />
          </div>
          {showModal && (
            <AddRegisterModal
              showModal={showModal}
              setShowModal={setShowModal}
            />
          )}
        </>
      ) : (
        <Result
          icon={<div className="coming-soon-icon">⏳</div>}
          title="Coming Soon! "
          subTitle="We're working on something amazing! Stay tuned for updates."
        />
      )}
    </div>
  );
};

export default Register;
