import { useMutation } from '@apollo/client';
import { MultiBackend, getBackendOptions } from '@minoru/react-dnd-treeview';
import { Col, Row } from 'antd';
import clsx from 'clsx';
import { toLower } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { useMedia } from 'react-use';
import { FixedSizeList } from 'react-window';
import { AddButton, EditIcon } from '../../../../../../assets/svg';
import {
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  NOMENCLATURE_TREE_TITLE_CLASS_BY_LEVEL,
} from '../../../../../../common/constants';
import CanPerform from '../../../../../../components/CanPerform';
import Portal from '../../../../../../components/Portal';
import SearchComponent from '../../../../../../components/SearchComponent';
import { MOVE_PROJECT_NOMENCLATURE_LEVEL } from '../../../../graphql/Mutation';
import CustomNode from './CustomNode';

const ROW_HEIGHT = 35;

const getTitle = (strTitle, searchValue) => {
  const index = toLower(strTitle)?.indexOf(toLower(searchValue));
  const beforeStr = strTitle?.substring(0, index);
  const searchText = strTitle?.slice(index, index + searchValue?.length);
  const afterStr = strTitle?.slice(index + searchValue?.length);
  return index > -1 ? (
    <span>
      {beforeStr}
      <span className="site-tree-search-value">{searchText}</span>
      {afterStr}
    </span>
  ) : (
    <span>{strTitle}</span>
  );
};

const transformDataToTree = (
  list,
  handleAdd,
  handleEdit,
  needActionButton,
  searchValue,
  isProjectActive,
  isDesktopViewport,
) => {
  const flatNodes = [];
  const traverse = (nodes, parent = 0) => {
    nodes?.forEach((item) => {
      const title = (
        <Row align="middle">
          <Col
            className={
              item?.isActive
                ? NOMENCLATURE_TREE_TITLE_CLASS_BY_LEVEL[item?.level]
                : 'disabled-tree-title'
            }
          >
            <span className="text-secondary font-size-10">
              L{item?.level} -&nbsp;
            </span>
            <span className="level-name">
              {searchValue ? getTitle(item?.name, searchValue) : item?.name}
            </span>
          </Col>
          <CanPerform
            action={ALLOWED_ACTION_KEYS.EDIT_NOMENCLATURE}
            type={ALLOWED_ACTION_TYPE.BOTH}
          >
            {isProjectActive &&
              isDesktopViewport &&
              item?.isActive &&
              needActionButton && (
                <Col className="node-action ml-16">
                  {item?.level < 7 && (
                    <AddButton
                      className="add-button mr-5"
                      onClick={() => handleAdd(item)}
                      height={20}
                      width={20}
                    />
                  )}
                  {item?.level !== 0 && (
                    <EditIcon
                      height={20}
                      width={20}
                      onClick={() => handleEdit(item)}
                    />
                  )}
                </Col>
              )}
          </CanPerform>
        </Row>
      );
      flatNodes.push({
        id: item?.id,
        parent: item?.parentLevelId || parent,
        text: title,
        droppable: item?.data?.length > 0,
        data: item,
      });
      if (item?.data?.length) {
        traverse(item?.data, item?.id);
      }
    });
  };
  traverse(list);
  return flatNodes;
};

const NomenclatureTree = ({
  nomenclatureList,
  setShowAddEditLevelModal,
  setSelectedTree,
  setCheckedTreeNode,
  checkedTreeNode,
  setIsDisabledNodeSelected,
  isDisabledNodeSelected,
  needActionButton = true,
  className = '',
  handleRefetch,
  isProjectActive,
  setOpenNodes,
  openNodes = {},
}) => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [searchValue, setSearchValue] = useState('');
  // eslint-disable-next-line no-undef
  const [listHeight, setListHeight] = useState(window.innerHeight - 335);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const updateHeight = () => setListHeight(window.innerHeight - 335);
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', updateHeight);
    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  const [moveProjectNomenclatureLevel] = useMutation(
    MOVE_PROJECT_NOMENCLATURE_LEVEL,
    {
      onCompleted() {
        handleRefetch();
      },
      onError() {},
    },
  );

  const handleAdd = (data) => {
    setSelectedTree(data);
    setShowAddEditLevelModal(true);
  };
  const handleEdit = (data) => {
    const newData = {
      ...data,
      isEdit: true,
    };
    setSelectedTree(newData);
    setShowAddEditLevelModal(true);
  };

  const initialNodes = useMemo(
    () =>
      transformDataToTree(
        nomenclatureList,
        handleAdd,
        handleEdit,
        needActionButton,
        searchValue,
        isProjectActive,
        isDesktopViewport,
      ),
    [nomenclatureList, searchValue],
  );

  const getVisibleNodes = useCallback(() => {
    const visibleNodes = [];
    const traverse = (parentId, depth) => {
      initialNodes
        .filter((node) => node?.parent === parentId)
        .forEach((node) => {
          visibleNodes.push({ ...node, depth });
          if (openNodes[node?.id]) {
            traverse(node?.id, depth + 1);
          }
        });
    };
    traverse(0, 0);
    return visibleNodes;
  }, [initialNodes, openNodes]);

  const visibleNodes = useMemo(getVisibleNodes, [
    openNodes,
    nomenclatureList,
    searchValue,
  ]);

  const handleToggle = useCallback((nodeId) => {
    setOpenNodes((prev) => ({ ...prev, [nodeId]: !prev[nodeId] }));
  }, []);

  const handleSelect = useCallback((node) => {
    setCheckedTreeNode((prev) =>
      // eslint-disable-next-line no-nested-ternary
      prev?.some((n) => n?.id === node?.id)
        ? prev?.filter((n) => n?.id !== node?.id)
        : prev?.length > 0
          ? [...prev, node]
          : [node],
    );
    if (setIsDisabledNodeSelected) {
      setIsDisabledNodeSelected(!node?.data?.isActive);
    }
  }, []);

  const findParentNodes = (nodeId, nodesMap) => {
    const parents = [];
    let current = nodesMap[nodeId]?.parent;
    while (current && current !== 0) {
      parents.push(current);
      current = nodesMap[current]?.parent;
    }
    return parents;
  };

  const onChange = (value) => {
    if (value) {
      const nodesMap = Object.fromEntries(
        initialNodes?.map((node) => [node?.id, node]),
      );

      const matchedNodeIds = initialNodes
        ?.filter((item) => toLower(item?.data?.name)?.includes(toLower(value)))
        ?.map((item) => item?.id);

      const expandedNodes = new Set();
      matchedNodeIds.forEach((id) => {
        expandedNodes.add(id);
        findParentNodes(id, nodesMap).forEach((parent) =>
          expandedNodes.add(parent),
        );
      });
      setOpenNodes((prev) => ({
        ...prev,
        ...Object.fromEntries([...expandedNodes]?.map((id) => [id, true])),
      }));
    }
    setSearchValue(value);
  };

  const handleMoveNode = useCallback((draggedId, newParentId, order) => {
    setCheckedTreeNode([]);
    moveProjectNomenclatureLevel({
      variables: {
        data: {
          id: draggedId,
          targetLevelId: newParentId,
          order,
        },
      },
    });
  }, []);

  const searchBar = () => {
    return (
      <div className={clsx('search-div', !needActionButton ? 'mb-10' : 'p-10')}>
        <SearchComponent getData={onChange} defaultValue={searchValue} />
      </div>
    );
  };

  return (
    <>
      {needActionButton ? (
        <Portal portalId="search-div">{searchBar()}</Portal>
      ) : (
        searchBar()
      )}
      <div className={`tree-structure  ${className}`}>
        <DndProvider backend={MultiBackend} options={getBackendOptions()}>
          <FixedSizeList
            className="thin-scrollbar tree-list"
            itemCount={visibleNodes.length}
            itemSize={ROW_HEIGHT}
            width="100%"
            height={listHeight}
          >
            {({ index, style }) => {
              const node = visibleNodes[index];
              return (
                <div style={style}>
                  <CustomNode
                    node={node}
                    depth={node?.depth}
                    isOpen={!!openNodes[node?.id]}
                    isSelected={checkedTreeNode?.some(
                      (n) => n?.id === node?.id,
                    )}
                    onToggle={() => handleToggle(node?.id)}
                    onSelect={handleSelect}
                    isProjectActive={isProjectActive}
                    needActionButton={needActionButton}
                    checkedTreeNode={checkedTreeNode}
                    isDisabledNodeSelected={isDisabledNodeSelected}
                    onMoveNode={handleMoveNode}
                    initialNodes={initialNodes}
                  />
                </div>
              );
            }}
          </FixedSizeList>
        </DndProvider>
      </div>
    </>
  );
};

export default NomenclatureTree;
