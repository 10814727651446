import { useLazyQuery } from '@apollo/client';
import { Button, Dropdown, Segmented, Space, Tag } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../../AppContext';
import { AddButton, Export } from '../../../../../../../assets/svg';
import {
  BREAKPOINTS,
  GA_EVENT,
  GA_LABEL,
  REGISTER_STATUS_CLASSNAME,
  ROUTES,
  TAB_KEYS,
} from '../../../../../../../common/constants';
import { Event } from '../../../../../../../common/trackEvents';
import useRouter from '../../../../../../../common/useRouter';
import { GET_REGISTER } from '../../../../../graphql/Queries';
import EntriesTable from './EntriesTable';
import SetsTable from './SetsTable';

const RegisterDetails = ({ registerId }) => {
  const {
    navigate,
    params: { projectId },
    location: { pathname },
  } = useRouter();

  const { getCurrentUser, getToken, getCurrentTenant, getTenantUser } =
    useContext(AppContext);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const tenantId = getCurrentTenant() || getTenantUser()?.tenant?.id;
  const currentUser = getCurrentUser();
  const [showListView, setShowListView] = useState(true);
  const [registerDetailsData, setRegisterDetailsData] = useState([]);
  const [isDataEmpty, setIsDataEmpty] = useState(true);
  const [getRegisterDetails] = useLazyQuery(GET_REGISTER, {
    fetchPolicy: 'cache-and-network',
    onError() {},
    onCompleted(res) {
      setRegisterDetailsData(res?.getRegister);
    },
  });

  useEffect(() => {
    if (registerId) {
      getRegisterDetails({ variables: { getRegisterId: registerId } });
    }
  }, []);
  const renderDownloadCsvButton = () => {
    const token = getToken();
    const url = `${process.env.REACT_APP_REPORT_SERVER_URL}/api/v1/export-csv/report/register/${showListView ? 'logs' : 'entries'}?access_token=Bearer ${token}&tenantId=${tenantId}&registerId=${registerId}`;
    return (
      <Button
        type="ghost"
        className="export-button ant-btn-icon-only d-flex b-0 justify-center width-percent-100"
        target="_blank"
        href={!isDataEmpty && url}
        onClick={() => {
          Event(GA_EVENT.EXPORT_PROJECT_REGISTER_CSV, {
            label: GA_LABEL.EXPORT_PROJECT_REGISTER_CSV,
            // eslint-disable-next-line no-undef
            pathname: window?.location?.href,
            project_id: projectId,
            project_register_id: registerId,
            user_id: currentUser?.id,
            user_name: currentUser?.name,
            tenant_id: currentUser?.tenantUser?.tenant?.id,
            tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
          });
        }}
      >
        CSV
      </Button>
    );
  };

  return (
    <div className="register-module">
      <div className="d-flex justify-end align-center mb-20">
        <div className="d-flex align-center width-percent-100 justify-end">
          <div>
            <Tag
              className={REGISTER_STATUS_CLASSNAME[registerDetailsData?.status]}
            >
              {registerDetailsData?.status}
            </Tag>
          </div>
          <div className="mr-10">
            <Segmented
              options={['Logs', 'Entries']}
              value={showListView ? 'Logs' : 'Entries'}
              onChange={(e) => setShowListView(e === 'Logs')}
            />
          </div>
          <Space className="d-flex justify-end">
            <Dropdown
              getPopupContainer={() =>
                // eslint-disable-next-line no-undef
                document.querySelector('.details-header')
              }
              menu={{
                items: [
                  {
                    key: 'csv',
                    label: renderDownloadCsvButton(),
                  },
                ],
              }}
              trigger={['click']}
              placement="bottom"
            >
              <Button
                shape="round"
                type="primary"
                disabled={isDataEmpty}
                icon={<Export height="26px" />}
                className="export-button fill-white d-flex b-0"
              >
                {isDesktopViewport && 'Export'}
              </Button>
            </Dropdown>
          </Space>
          {showListView && (
            <div className="d-flex align-center">
              <Button
                shape="round"
                icon={<AddButton />}
                type="primary"
                onClick={() => {
                  navigate(
                    `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.REGISTER}/${registerId}/add-set`,
                    {
                      state: {
                        registerProperty: registerDetailsData?.registerDetails,
                        registerDetailsData,
                        currentPath: pathname,
                      },
                    },
                  );
                }}
              >
                {isDesktopViewport && 'Add'}
              </Button>
            </div>
          )}
        </div>
      </div>
      {showListView && registerDetailsData ? (
        <SetsTable
          registerDetailsData={registerDetailsData}
          showListView={showListView}
          setIsDataEmpty={setIsDataEmpty}
        />
      ) : (
        <EntriesTable
          registerDetailsData={registerDetailsData}
          showListView={showListView}
          setIsDataEmpty={setIsDataEmpty}
        />
      )}
    </div>
  );
};
export default RegisterDetails;
