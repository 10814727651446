import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { App, ConfigProvider } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Workbox } from 'workbox-window';
import packageJson from '../package.json';
import { AppContextProvider } from './AppContext';
import RoutesWrapper from './Routes';
import client from './apollo';
import AppContextHolder from './common/AppContextHolder';
import { OnlineStatusProvider } from './common/useNetworkDetect';
import './index.css';
import './styles/main.less';
import themeJson from './styles/style.json';

const VERSION = require('../package.json').version;

// eslint-disable-next-line no-console
console?.log('APP_VERSION', VERSION);

// eslint-disable-next-line no-undef
if ('serviceWorker' in navigator) {
  const wb = new Workbox('/sw.js');

  wb.register();
}

if (process.env.REACT_APP_ENV !== 'dev') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    release: `${packageJson.name}@${packageJson.version}`,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  });
}

// eslint-disable-next-line no-undef
const root = ReactDOM?.createRoot(document?.getElementById('root'));
root?.render(
  <ApolloProvider client={client}>
    <AppContextProvider>
      <App className="fill-height">
        <AppContextHolder />
        <ConfigProvider theme={themeJson}>
          <OnlineStatusProvider>
            <RoutesWrapper />
          </OnlineStatusProvider>
        </ConfigProvider>
      </App>
    </AppContextProvider>
  </ApolloProvider>,
);
