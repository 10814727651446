import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Switch,
} from 'antd';
import { filter, includes, keys, map, toLower } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { DeleteButton } from '../../../../../../assets/svg';
import {
  BREAKPOINTS,
  PROPERTY_TYPES,
  PROPERTY_TYPES_LABELS,
  REGISTER_STATUS_LABEL,
} from '../../../../../../common/constants';
import useRouter from '../../../../../../common/useRouter';
import { formValidatorRules } from '../../../../../../common/utils';
import CommonSelect from '../../../../../../components/CommonSelect';
import {
  CREATE_REGISTER_DETAILS,
  UPDATE_REGISTER_DETAIL,
} from '../../../../graphql/Mutation';
import { GET_REGISTER } from '../../../../graphql/Queries';

const { required, characterWithoutWhiteSpace } = formValidatorRules;

const OptionUi = (isDesktopViewport) => {
  return (
    <>
      <Form.List name="options">
        {(fields, { add, remove }) => {
          return (
            <>
              <Row gutter={[15, 15]} className="width-percent-100">
                {fields.map((field) => (
                  <Col span={24} key={field?.key}>
                    <Row gutter={[30]}>
                      <Col flex="auto">
                        <Form.Item
                          {...field}
                          label="Value"
                          name={[field.name, 'option']}
                          fieldKey={[field?.fieldKey, 'option']}
                          validateTrigger={['onChange', 'onClick']}
                          rules={[
                            required,
                            characterWithoutWhiteSpace,
                            {
                              max: 250,
                              message: 'Maximum 250 characters are allowed',
                            },
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                const items = map(
                                  getFieldValue()?.options,
                                  (item) => item?.option,
                                );
                                if (
                                  filter(
                                    items,
                                    (val) => toLower(val) === toLower(value),
                                  )?.length > 1 &&
                                  value
                                ) {
                                  // eslint-disable-next-line prefer-promise-reject-errors
                                  return Promise.reject(
                                    'should be an unique option',
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input placeholder="Enter Value" />
                        </Form.Item>
                      </Col>
                      <Col className="d-flex align-center">
                        {fields?.length > 2 && (
                          <Form.Item
                            label={isDesktopViewport && ' '}
                            className="mb-0"
                          >
                            <DeleteButton
                              className="pointer"
                              onClick={() => {
                                if (fields?.length > 2) remove(field?.name);
                              }}
                            />
                          </Form.Item>
                        )}
                      </Col>
                    </Row>
                  </Col>
                ))}
                <Row>
                  <Col className="mb-15">
                    <Button
                      className="grey-button"
                      shape="round"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      Add Option
                    </Button>
                  </Col>
                </Row>
              </Row>
            </>
          );
        }}
      </Form.List>
    </>
  );
};

const AddEditPropertyModal = ({
  createRegisterData,
  showModal,
  setShowModal,
  propertyDetails,
  setPropertyDetails,
  setTableColumns,
  editSelectedProperty,
  setEditSelectedProperty,
}) => {
  const {
    params: { registerId },
  } = useRouter();
  const [form] = Form.useForm();
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [displayOption, setDisplayOption] = useState(false);
  const [displayOptionValue, setDisplayOptionValue] = useState(
    OptionUi(isDesktopViewport),
  );
  const [isOptionType, setIsOptionType] = useState(false);

  const [getRegister] = useLazyQuery(GET_REGISTER, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted(response) {
      const registerDetails = response?.getRegister?.registerDetails;
      const registerFormFields = response?.getRegister?.registerFormFields;
      setPropertyDetails(registerDetails);
      setTableColumns(registerFormFields);
      setShowModal(false);
    },
  });

  const [createRegisterDetails] = useMutation(CREATE_REGISTER_DETAILS, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted() {
      getRegister({ variables: { getRegisterId: registerId } });
    },
  });

  const [updateRegisterDetails] = useMutation(UPDATE_REGISTER_DETAIL, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted() {
      setEditSelectedProperty();
      getRegister({ variables: { getRegisterId: registerId } });
    },
  });

  const handleTypeChange = (options) => {
    switch (options?.value) {
      case PROPERTY_TYPES.OPTIONS:
        setDisplayOption(true);
        setIsOptionType(true);
        setDisplayOptionValue(
          OptionUi(
            isDesktopViewport,
            includes([
              REGISTER_STATUS_LABEL.PUBLISHED,
              REGISTER_STATUS_LABEL.ARCHIVED,
            ]),
          ),
        );
        break;

      case PROPERTY_TYPES.CHECKBOXES:
        setDisplayOption(true);
        setIsOptionType(true);
        setDisplayOptionValue(
          OptionUi(
            isDesktopViewport,
            includes([
              REGISTER_STATUS_LABEL.PUBLISHED,
              REGISTER_STATUS_LABEL.ARCHIVED,
            ]),
          ),
        );
        break;

      default:
        setDisplayOption(false);
        setIsOptionType(false);
        break;
    }
  };

  useEffect(() => {
    if (editSelectedProperty) {
      const transformedValues = {
        ...editSelectedProperty,
        options:
          editSelectedProperty?.options?.map((option) => ({ option })) || null,
      };
      form.setFieldsValue(transformedValues);
      handleTypeChange({ value: editSelectedProperty?.type });
    }
  }, [editSelectedProperty]);

  const handleSelect = (labeledValue, options) => {
    handleTypeChange(options);
    if (labeledValue === PROPERTY_TYPES.OPTIONS) {
      form.setFieldsValue({ options: [{}, {}] });
    } else if (labeledValue === PROPERTY_TYPES.CHECKBOXES) {
      form.setFieldsValue({ options: [{}, {}] });
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    setEditSelectedProperty();
  };

  const onPropertySubmitFinish = async () => {
    const formValues = form.getFieldsValue();
    const options = formValues?.options?.map((value) => value?.option);
    if (!createRegisterData && registerId) {
      if (!editSelectedProperty) {
        const result = [];
        if (propertyDetails?.find((item) => item?.name === formValues?.name)) {
          message.destroy();
          message.error('Detail name already exists.');
        } else {
          result?.push(formValues);
          setPropertyDetails([...propertyDetails, ...result]);
          await createRegisterDetails({
            variables: {
              data: { ...formValues, options: options || null, registerId },
            },
          });
        }
      } else if (
        propertyDetails?.find(
          (item) =>
            item?.name === formValues?.name &&
            item.id !== editSelectedProperty?.id,
        )
      ) {
        message.destroy();
        message.error('Detail name already exists.');
      } else {
        await updateRegisterDetails({
          variables: {
            updateRegisterDetailId: editSelectedProperty?.id,
            data: { ...formValues, options: options || null },
          },
        });
      }
    } else {
      formValues.options = options || null;
      formValues.tempId = `temp_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
      if (editSelectedProperty?.tempId) {
        const nameExists = propertyDetails?.some(
          (item) =>
            item?.name === formValues?.name &&
            item?.tempId !== editSelectedProperty?.tempId,
        );
        if (nameExists) {
          message.destroy();
          message.error('Detail name already exists.');
        } else {
          formValues.tempId = editSelectedProperty.tempId;
          const updatedProperties = propertyDetails.map((prop) =>
            prop.tempId === editSelectedProperty.tempId ? formValues : prop,
          );
          setPropertyDetails(updatedProperties);
          setShowModal(false);
        }
      } else {
        const result = [];
        if (propertyDetails?.find((item) => item?.name === formValues?.name)) {
          message.destroy();
          message.error('Detail name already exists.');
        } else {
          result.push(formValues);
          setPropertyDetails([...propertyDetails, ...result]);
          setShowModal(false);
        }
      }
    }
  };

  return (
    <div>
      <Modal
        open={showModal}
        footer={false}
        centered
        className="stageitem-modal"
        maskClosable={false}
        closable={false}
        form={form}
      >
        <h2 className="mb-15">
          {editSelectedProperty ? 'Edit ' : 'Add '} Property
        </h2>
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            type: 'TEXT',
            isRequired: true,
            options: [{}, {}],
          }}
          className="mt-16"
          onFinish={onPropertySubmitFinish}
        >
          <Form.Item
            rules={[
              required,
              characterWithoutWhiteSpace,
              { max: 250, message: 'Maximum 250 characters are allowed' },
            ]}
            name="name"
            label="Property Name"
          >
            <Input placeholder="Enter property name" allowClear />
          </Form.Item>
          <Row gutter={30}>
            <Col span={isDesktopViewport ? 18 : 24}>
              <Form.Item name="type" label="Type">
                <CommonSelect
                  className="mr-3"
                  placeholder="Select Type"
                  onSelect={handleSelect}
                  options={[
                    ...keys(PROPERTY_TYPES).map((type) => {
                      return {
                        key: PROPERTY_TYPES_LABELS[type],
                        value: type,
                        label: PROPERTY_TYPES_LABELS[type],
                      };
                    }),
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={isDesktopViewport ? 6 : 24}>
              <Form.Item
                name="isRequired"
                label="Required"
                valuePropName="checked"
              >
                <Switch defaultChecked />
              </Form.Item>
            </Col>
          </Row>
          {displayOption && (
            <>
              <Divider dashed />
              {isOptionType && <h3>Options</h3>}
              {displayOptionValue}
            </>
          )}
          <div className="form-buttons">
            <Button
              shape="round"
              className="cancel-button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              shape="round"
              type="primary"
              className="save-button"
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default AddEditPropertyModal;
