import { useMutation } from '@apollo/client';
import { Button, Modal } from 'antd';
import React from 'react';
import { DELETE_REGISTER_FORM_FIELD } from '../../../../../graphql/Mutation';

const DeleteTableFieldModal = ({
  name,
  showModal,
  setShowModal,
  selectedField,
  setSelectedField,
  tableHeaders,
  setTableHeaders,
  handleRefetch,
  createRegisterData,
}) => {
  const [deleteRegisterFormField, { loading: deleteLoading }] = useMutation(
    DELETE_REGISTER_FORM_FIELD,
    {
      onError() {},
      onCompleted: () => {
        setShowModal(false);
        setSelectedField();
        handleRefetch();
      },
    },
  );
  const handleOk = () => {
    if (createRegisterData) {
      const updatedField = tableHeaders?.filter(
        (prop) => prop?.tempId !== selectedField?.tempId,
      );
      setTableHeaders(updatedField);
      setShowModal(false);
      setSelectedField();
    } else {
      deleteRegisterFormField({
        variables: {
          id: selectedField?.id,
        },
      });
    }
  };
  const handleCancel = () => {
    setShowModal(false);
    setSelectedField();
  };
  return (
    <div>
      <Modal
        maskClosable={false}
        centered
        closable={false}
        footer={null}
        open={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <h2>Delete Field</h2>
        <p>Are you sure you want to delete the field {name} ?</p>
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
            disabled={deleteLoading}
          >
            No, Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            onClick={handleOk}
            loading={deleteLoading}
          >
            Yes, Delete
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteTableFieldModal;
