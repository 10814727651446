import { useLazyQuery } from '@apollo/client';
import { Button, Card, Form, Input, Upload, message } from 'antd';
import { findIndex, isNaN } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../../../AppContext';
import { ForwardArrow, ProjectIcon } from '../../../../../../assets/svg';
import {
  CSV_SAMPLE_FILES,
  PICK_REGISTER_LABEL,
  REGISTER_STATUS_LABEL,
  ROUTES,
  TAB_KEYS,
} from '../../../../../../common/constants';
import useRouter from '../../../../../../common/useRouter';
import { formValidatorRules } from '../../../../../../common/utils';
import { GET_REGISTER, GET_SINGLE_PROJECT } from '../../../../graphql/Queries';
import ArchiveRegisterModal from './ArchiveRegisterModal';

const AddEditRegister = () => {
  const { getCurrentUser, dispatch } = useContext(AppContext);
  const currentUserId = getCurrentUser()?.id || {};
  const {
    navigate,
    location,
    params: { projectId, registerId },
  } = useRouter();
  const { required, characterWithoutWhiteSpace } = formValidatorRules;
  const [form] = Form.useForm();
  // eslint-disable-next-line no-unused-vars
  const [pickFrom, setPickFrom] = useState(PICK_REGISTER_LABEL.CREATE);
  const [showArchiveRegisterModal, setShowArchiveRegisterModal] =
    useState(false);
  const [status, setStatus] = useState();
  const [registerValues, setRegisterValues] = useState();
  const navFilter = location?.state?.navFilter;
  const navPagination = location?.state?.navPagination;
  const [registerName, setRegisterName] = useState();
  const [csvData, setCsvData] = useState(null);
  const [fileList, setFileList] = useState([]);

  const handleFileUpload = async (info) => {
    const { status: uploadStatus } = info.file;
    setFileList(info.fileList.slice(-1));

    if (uploadStatus === 'done' || uploadStatus === 'uploading') {
      try {
        const text = await info.file.text();
        const rows = text.split('\n');
        const headers = rows[0].split(',').map((header) => header.trim());
        const data = rows
          .slice(1)
          .filter((row) => row.trim())
          .map((row) => {
            const values = row.split(',').map((value) => value.trim());
            const rowData = {};
            headers.forEach((header, index) => {
              rowData[header] = values[index];
            });
            return rowData;
          });
        setCsvData(data);
        message.success(`${info.file.name} file uploaded successfully.`);
      } catch (error) {
        message.error('Failed to parse CSV file. Please check the format.');
      }
    } else if (uploadStatus === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const [getWebProject, { data: projectData }] = useLazyQuery(
    GET_SINGLE_PROJECT,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        dispatch({
          type: 'SET_PROJECT_DETAILS',
          data: JSON.stringify(res?.getProject),
        });
        const index = findIndex(
          res?.getProject?.projectUsers,
          (user) => Number(user?.userId) === Number(currentUserId),
        );
        if (index !== -1) {
          const loggedInUserRole =
            res?.getProject?.projectUsers?.[index]?.roles;
          dispatch({ type: 'SET_PROJECT_ROLE', data: loggedInUserRole });
        } else {
          dispatch({ type: 'SET_PROJECT_ROLE', data: '' });
        }
      },
    },
  );

  const [getRegister] = useLazyQuery(GET_REGISTER, {
    fetchPolicy: 'cache-and-network',
    onError() {},
    onCompleted(res) {
      const data = res?.getRegister;
      setRegisterName(data?.name);
      setStatus(data?.status);
      const result = { name: data?.name, status: data?.status };
      form.setFieldsValue(result);
    },
  });

  useEffect(() => {
    if (!isNaN(Number(projectId))) {
      getWebProject({
        variables: {
          id: projectId,
        },
      });
    }
  }, [projectId]);

  useEffect(() => {
    if (registerId) {
      getRegister({ variables: { getRegisterId: registerId } });
    }
  }, [registerId]);

  const renderRegisterTypeUi = (type) => {
    switch (type) {
      case PICK_REGISTER_LABEL.CREATE:
        return (
          <>
            <Form.Item
              rules={[
                required,
                characterWithoutWhiteSpace,
                {
                  max: 250,
                  message: 'Maximum 250 characters are allowed',
                },
              ]}
              name="name"
              label="Register Name"
            >
              <Input placeholder="Enter Name" />
            </Form.Item>
          </>
        );

      case PICK_REGISTER_LABEL.IMPORT:
        return (
          <>
            <div className="mb-10">
              <Upload
                maxCount={1}
                fileList={fileList}
                onChange={handleFileUpload}
                accept=".csv"
                beforeUpload={(file) => {
                  const isCsv =
                    file.type === 'text/csv' || file.name.endsWith('.csv');
                  if (!isCsv) {
                    message.error('You can only upload CSV files!');
                  }
                  return false;
                }}
              >
                <Button
                  shape="round"
                  type="primary"
                  disabled={fileList.length > 0}
                >
                  Select File
                </Button>
              </Upload>
            </div>
            <h4>
              Use the
              <a
                className="sample-file pointer ml-5 mr-5"
                href={CSV_SAMPLE_FILES.REGISTER_CSV}
              >
                Sample File
              </a>
              to fill the data. <br />
              Upload the file after verifying the format of your file with the
              sample file.
            </h4>
            {csvData && (
              <div className="mt-10">
                <h3>Preview:</h3>
                <div
                  className="csv-preview"
                  style={{
                    border: '1px solid #d9d9d9',
                    borderRadius: '8px',
                    padding: '16px',
                    backgroundColor: '#fafafa',
                    maxHeight: '300px',
                    overflowY: 'auto',
                  }}
                >
                  {csvData.slice(0, 3).map((row) => (
                    <div
                      key={Object.values(row).join('-')}
                      className="csv-row"
                      style={{
                        marginBottom: '8px',
                        padding: '8px',
                        backgroundColor: '#fff',
                        borderRadius: '4px',
                        boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
                      }}
                    >
                      {Object.entries(row).map(([key, value]) => (
                        <span
                          key={`${key}-${value}`}
                          style={{
                            display: 'inline-block',
                            marginRight: '16px',
                            color: '#666',
                          }}
                        >
                          <strong style={{ color: '#333' }}>{key}:</strong>{' '}
                          {value}
                        </span>
                      ))}
                    </div>
                  ))}
                  {csvData.length > 3 && (
                    <div
                      style={{
                        marginTop: '8px',
                        color: '#999',
                        textAlign: 'center',
                        fontStyle: 'italic',
                      }}
                    >
                      ... and {csvData.length - 3} more rows
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        );

      default:
        break;
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const onFormSubmit = async () => {
    const data = form.getFieldsValue();
    navigate(`${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.REGISTER}/draft`, {
      state: {
        createRegisterData: data,
      },
    });
  };

  return (
    <div className="add-project-wrapper">
      <div className="project-details-title page-header">
        <div className="project-details-title-text d-flex align-center">
          <ProjectIcon
            onClick={() => {
              navigate(ROUTES.PROJECTS);
            }}
            className="pointer"
          />
          <ForwardArrow />
          <h1
            className="pointer mr-10 text-primary"
            onClick={() => {
              navigate(`${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.REGISTER}`, {
                state: {
                  navFilter,
                  navPagination,
                },
              });
            }}
          >
            {projectData?.getProject?.name}
          </h1>
          <ForwardArrow />
          {registerId ? (
            <>
              <h1 className="pointer mr-10">{registerName}</h1>
              <ForwardArrow />
              <h1 className="mr-10 text-primary">Edit Register</h1>
            </>
          ) : (
            <h1 className="mr-10">Add Register</h1>
          )}
        </div>
      </div>
      <div>
        <div className="add-register-card-wrapper">
          <Card bordered={false}>
            <h1>{registerId ? 'Edit' : 'Add'} Register</h1>
            {/* {!registerId && <div className="mb-5">Pick From :</div>} */}
            {/*
              commented for future use
              <Form.Item
                name="type"
                className="mb-20"
                initialValue={PICK_REGISTER_LABEL.CREATE}
              >
                <Radio.Group
                  value={pickFrom}
                  onChange={(e) => setPickFrom(e?.target?.value)}
                  size="large"
                >
                  <Radio value={PICK_REGISTER_LABEL.CREATE}>Create</Radio>
                  <Radio value={PICK_REGISTER_LABEL.IMPORT}>Import</Radio>
                </Radio.Group>
               </Form.Item> */}
            <Form
              layout="vertical"
              form={form}
              className="gx-login-form gx-form-row0"
              onFinish={onFormSubmit}
            >
              {registerId && (
                <div className="mb-10">
                  <p>Do you want to archive register?</p>
                  <Button
                    shape="round"
                    type="primary"
                    onClick={() => {
                      const result = form.getFieldsValue();
                      setRegisterValues(result);
                      setShowArchiveRegisterModal(true);
                    }}
                  >
                    {status === REGISTER_STATUS_LABEL.ARCHIVED
                      ? 'Delete'
                      : 'Archive'}
                  </Button>
                </div>
              )}
              {renderRegisterTypeUi(pickFrom)}
              <div className="form-buttons">
                <Button
                  shape="round"
                  className="cancel-button"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  shape="round"
                  className="save-button"
                  type="primary"
                  htmlType="submit"
                >
                  Save
                </Button>
              </div>
            </Form>
          </Card>
        </div>
      </div>
      {showArchiveRegisterModal && (
        <ArchiveRegisterModal
          showModal={showArchiveRegisterModal}
          setShowModal={setShowArchiveRegisterModal}
          registerValues={registerValues}
          status={status}
        />
      )}
    </div>
  );
};

export default AddEditRegister;
