import { InboxOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import clsx from 'clsx';
import { debounce, map } from 'lodash';
import moment from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import React, { useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../../AppContext';
import {
  AddButton,
  ArchiveOutline,
  DeleteButton,
  EditButton,
  SaveOutline,
} from '../../../../../../../assets/svg';
import {
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  CONFIRMATION_TYPES,
  DATE_ISO_FORMAT,
  DEFAULTDATEFORMAT,
  PROPERTY_TYPES,
  REGISTER_STATUS_LABEL,
  ROUTES,
  TAB_KEYS,
} from '../../../../../../../common/constants';
import useRouter from '../../../../../../../common/useRouter';
import {
  formValidatorRules,
  toCamelCase,
} from '../../../../../../../common/utils';
import CanPerform from '../../../../../../../components/CanPerform';
import CommonCard from '../../../../../../../components/CommonCard';
import CommonSelect from '../../../../../../../components/CommonSelect';
import EllipsisText from '../../../../../../../components/EllipsisText';
import RouterPrompt from '../../../../../../../components/RouterPrompt';
import {
  CREATE_NEW_REGISTER,
  UPDATE_REGISTER,
  UPDATE_REGISTER_LOG_DETAIL_ENTRY_LIST,
} from '../../../../../graphql/Mutation';
import {
  GET_REGISTER,
  GET_REGISTER_LOG_DETAILS_ENTRY,
  GET_REGISTER_LOGS,
  REGISTER_LIST,
} from '../../../../../graphql/Queries';
import ConfirmationModal from '../../eqcTypes/eqcTypeDetails/ConfirmationModal';
import AddEditPropertyModal from '../AddEditPropertyModal';
import AddRegisterFieldsTable from '../AddRegisterFieldsTable';
import ArchiveRegisterModal from '../ArchiveRegisterModal';
import DeletePropertyModal from './DeletePropertyModal';
import SaveRegisterLogModal from './SaveRegisterLogModal';

const { required, characterWithoutWhiteSpace } = formValidatorRules;
const MyDatePicker = DatePicker.generatePicker(momentGenerateConfig);

const RegisterPropertyDetails = ({
  createRegisterData,
  registerDetailsData,
}) => {
  const { state } = useContext(AppContext);
  const [form] = Form.useForm();
  const {
    location,
    navigate,
    params: { projectId, registerId, registerLogId },
  } = useRouter();
  const { number } = formValidatorRules;
  const registerName = useWatch('registerName', form);

  const [registerData, setRegisterData] = useState();
  const [isEditableProperty, setIsEditableProperty] = useState(false);
  const properties = [];
  const [propertyDetails, setPropertyDetails] = useState([]);
  const [editSelectedProperty, setEditSelectedProperty] = useState();
  const [deleteSelectedProperty, setDeleteSelectedProperty] = useState();
  const [isEditable, setIsEditable] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [confirmationType, setConfirmationType] = useState(
    CONFIRMATION_TYPES?.EDIT,
  );
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showAddPropertyModal, setShowAddPropertyModal] = useState(false);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  const [registerList] = useLazyQuery(REGISTER_LIST, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted() {},
  });

  const [getRegisterLogs] = useLazyQuery(GET_REGISTER_LOGS, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted() {},
  });

  const [getRegisterLogDetails] = useLazyQuery(GET_REGISTER_LOG_DETAILS_ENTRY, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const data = res?.registerLogDetailEntryList?.data;
      const processValue = (item, value) => {
        const isDefaultDateFormat =
          /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/.test(value);
        if (
          item?.type === PROPERTY_TYPES.DATE &&
          value &&
          moment(
            value,
            isDefaultDateFormat ? DEFAULTDATEFORMAT : DATE_ISO_FORMAT,
          )?.isValid()
        ) {
          return moment(
            value,
            isDefaultDateFormat ? DEFAULTDATEFORMAT : DATE_ISO_FORMAT,
          );
        }
        return value || null;
      };

      const result = data?.map((item) => {
        const value = item?.registerLogDetailEntries?.value;
        return {
          name: item?.name,
          index: toCamelCase(item?.name),
          id: item?.registerLogDetailEntries?.id,
          value: processValue(item, value),
          registerDetailId: item?.id,
          type: item?.type,
          options: item?.options,
          isRequired: item?.isRequired,
          updatedAt: item?.registerLogDetailEntries?.updatedAt,
          createdAt: item?.registerLogDetailEntries?.createdAt,
        };
      });
      setPropertyDetails(result);
    },
    onError() {},
  });

  // Commented for future use
  // const [getRegisterLogFormFieldsEntry] = useLazyQuery(
  //   GET_REGISTER_LOG_FORM_FIELDS_ENTRY_LIST,
  //   {
  //     fetchPolicy: 'cache-and-network',
  //     onError() {},
  //     onCompleted(res) {
  //       const tableColumnData =
  //         res?.registerLogFormFieldEntryList?.registerFormFields;
  //       const result = map(tableColumnData, (item) => {
  //         return {
  //           id: item?.id,
  //           name: item?.name?.toUpperCase(),
  //           dataIndex: camelCase(item?.name),
  //           index: camelCase(item?.name),
  //           isRequired: item?.isRequired,
  //           type: item?.type,
  //           options: item?.options,
  //           sequence: item?.sequence,
  //         };
  //       });
  //       // eslint-disable-next-line no-console
  //       console.log('result', result);
  //       setTableColumns(result);
  //     },
  //   },
  // );

  const [getRegister] = useLazyQuery(GET_REGISTER, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted(response) {
      setRegisterData(response?.getRegister);
      form.setFieldValue('registerName', response?.getRegister?.name);
      const registerDetails = response?.getRegister?.registerDetails;
      const registerFormFields = response?.getRegister?.registerFormFields;
      setPropertyDetails(registerDetails);
      setTableColumns(registerFormFields);
    },
  });

  useEffect(() => {
    if (createRegisterData?.name) {
      form.setFieldValue('registerName', createRegisterData?.name);
    }
  }, [createRegisterData]);

  const [createRegister] = useMutation(CREATE_NEW_REGISTER, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted() {
      navigate(`${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.REGISTER}`);
    },
  });

  const [updateRegister] = useMutation(UPDATE_REGISTER, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted() {
      registerList({ variables: { projectId } });
    },
  });

  const [updateRegisterLogDetail] = useMutation(
    UPDATE_REGISTER_LOG_DETAIL_ENTRY_LIST,
    {
      fetchPolicy: 'network-only',
      onError() {},
      onCompleted() {
        getRegisterLogDetails({ variables: { registerLogId } });
      },
    },
  );

  const transformDataToColumns = (data) => {
    if (!data || !data.length) return [];
    const sampleObject = data[0];

    const columns = Object.keys(sampleObject)
      .filter((key) => !['registerLogId', 'createdAt'].includes(key))
      .map((key) => ({
        title: key,
        dataIndex: key,
        key,
        index: key,
      }));

    return columns;
  };

  useEffect(() => {
    if (registerDetailsData?.length) {
      const columns = transformDataToColumns(registerDetailsData);
      setTableColumns(columns);
    }
  }, [registerDetailsData]);

  useEffect(() => {
    if (createRegisterData) {
      setIsEditable(true);
    }
  }, []);

  useEffect(() => {
    if (
      !createRegisterData &&
      location?.pathname !==
        `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.REGISTER}/${registerId}/edit-control`
    ) {
      getRegisterLogDetails({ variables: { registerLogId } });
      // Commented for future use
      // getRegisterLogFormFieldsEntry({ variables: { registerLogId } });
    }
  }, []);

  useEffect(() => {
    if (
      location?.pathname ===
      `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.REGISTER}/${registerId}/edit-control`
    ) {
      getRegisterLogs({
        variables: { registerId, filter: { limit: 10, skip: 0 } },
      });
      getRegister({ variables: { getRegisterId: registerId } });
      setIsEditable(true);
    }
  }, []);

  useEffect(() => {
    const updatedFields = map(
      registerDetailsData?.registerFormFields,
      (field) => ({
        ...field,
        name: field?.name?.toUpperCase(),
        dataIndex: toCamelCase(field?.name),
        index: toCamelCase(field?.name),
      }),
    );
    setTableColumns(updatedFields);
  }, [registerDetailsData]);

  const handleAction = async () => {
    setIsEditableProperty(false);
  };

  const handleAddProperty = () => {
    setEditSelectedProperty();
    setShowAddPropertyModal(true);
  };

  const handleEditProperty = (property) => {
    setEditSelectedProperty(property);
    setShowAddPropertyModal(true);
  };

  const handleDeleteProperty = (property) => {
    setDeleteSelectedProperty(property);
    setShowDeleteModal(true);
  };

  const handleRegisterLogDetailRefetch = async () => {
    await getRegisterLogDetails({ variables: { registerLogId } });
  };

  const handleRegisterArchive = () => {
    setShowArchiveModal(true);
  };

  const handleSave = () => {
    if (createRegisterData && propertyDetails?.length && tableHeaders?.length) {
      const filteredPropertyDetails = propertyDetails.map(
        ({ tempId, ...rest }) => rest,
      );
      const filteredColumns = map(
        tableHeaders,
        ({ index, dataIndex, tempId, formula, key, ...rest }) => rest,
      );
      createRegister({
        variables: {
          data: {
            name: registerName,
            type: 'SCRATCH',
            projectId,
            status: REGISTER_STATUS_LABEL.LIVE,
            detail: filteredPropertyDetails,
            formField: filteredColumns,
          },
        },
      });
    }

    if (registerData?.status === REGISTER_STATUS_LABEL.DRAFT) {
      setShowArchiveModal(true);
    }
  };

  const handleDetailBlur = async (field) => {
    const initialValues = propertyDetails?.reduce((acc, item) => {
      acc[item?.index] = {
        id: item?.registerDetailId,
        registerDetailId: item?.id,
        value: item?.value,
      };
      return acc;
    }, {});
    const detailValues = form?.getFieldsValue();
    const updatedValue = detailValues?.[field];
    const fieldConfig = propertyDetails?.find((item) => item?.index === field);

    if (initialValues?.[field]?.id) {
      let processedValue = updatedValue;

      if (fieldConfig?.type === PROPERTY_TYPES.DATE && updatedValue) {
        if (moment.isMoment(updatedValue)) {
          processedValue = updatedValue?.format(DEFAULTDATEFORMAT);
        } else {
          processedValue = updatedValue;
        }
      }
      if (
        fieldConfig?.type === PROPERTY_TYPES.CHECKBOXES &&
        Array.isArray(updatedValue)
      ) {
        processedValue = updatedValue?.join(',');
      }

      processedValue =
        processedValue === undefined || processedValue === null
          ? ''
          : processedValue;

      const result = {
        registerDetailId: initialValues?.[field]?.id,
        id: initialValues?.[field]?.registerDetailId,
        value: processedValue,
      };

      await updateRegisterLogDetail({
        variables: { registerLogId, entries: [result] },
      });
      if (fieldConfig?.isRequired) {
        form.validateFields([field]);
      }
    }
  };
  const debouncedHandleDetailBlur = debounce(handleDetailBlur, 500);

  useEffect(() => {
    return () => {
      debouncedHandleDetailBlur.cancel();
    };
  }, []);

  const renderProperties = (property) => {
    const result = property.reduce((acc, item) => {
      let { value } = item;
      if (
        item?.type === PROPERTY_TYPES.CHECKBOXES &&
        typeof value === 'string' &&
        value
      ) {
        value = value.split(',');
      }
      acc[item.index] = value || undefined;
      return acc;
    }, {});

    form?.setFieldsValue(result);

    return map(property, (detail) => {
      const options = detail?.options?.map((option) => ({
        label: option,
        value: option,
      }));

      if (!createRegisterData && !isEditable) {
        switch (detail?.type) {
          case PROPERTY_TYPES.TEXT:
            return (
              <Form.Item
                name={detail?.index}
                label={detail?.name}
                rules={[
                  {
                    required: detail?.isRequired,
                    message: 'This field is required!',
                    validateTrigger: ['onChange', 'onBlur'],
                  },
                  characterWithoutWhiteSpace,
                  {
                    max: 250,
                    message: 'Text cannot be more than 250 characters',
                  },
                ]}
              >
                <Input
                  placeholder="Enter text"
                  onChange={(e) => {
                    const { value } = e.target;
                    form.setFieldsValue({ [detail.index]: value || undefined });
                    debouncedHandleDetailBlur(detail?.index);
                  }}
                  onBlur={() => {
                    debouncedHandleDetailBlur.cancel();
                    handleDetailBlur(detail?.index);
                  }}
                />
              </Form.Item>
            );

          case PROPERTY_TYPES.DATE:
            return (
              <Form.Item
                name={detail?.index}
                label={detail?.name}
                rules={[
                  {
                    required: detail?.isRequired,
                    message: 'This field is required!',
                    validateTrigger: ['onChange', 'onBlur'],
                  },
                ]}
              >
                <MyDatePicker
                  onChange={(date) => {
                    form.setFieldsValue({ [detail.index]: date || undefined });
                    handleDetailBlur(detail?.index);
                  }}
                  className="width-percent-100"
                  format={DEFAULTDATEFORMAT}
                />
              </Form.Item>
            );

          case PROPERTY_TYPES.OPTIONS:
            return (
              <Form.Item
                name={detail?.index}
                label={detail?.name}
                rules={[
                  {
                    required: detail?.isRequired,
                    message: 'This field is required!',
                    validateTrigger: ['onChange', 'onBlur'],
                  },
                ]}
              >
                <CommonSelect
                  name={detail?.name}
                  options={options}
                  placeholder="Select Options"
                  onChange={(value) => {
                    form.setFieldsValue({ [detail.index]: value || undefined });
                    handleDetailBlur(detail?.index);
                  }}
                />
              </Form.Item>
            );

          case PROPERTY_TYPES.MULTI_LINE_TEXT:
            return (
              <Form.Item
                name={detail?.index}
                label={detail?.name}
                rules={[
                  {
                    required: detail?.isRequired,
                    message: 'This field is required!',
                    validateTrigger: ['onChange', 'onBlur'],
                  },
                  {
                    max: 350,
                    message: 'Maximum 350 characters are allowed',
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Enter text"
                  rows={4}
                  onChange={(e) => {
                    const { value } = e.target;
                    form.setFieldsValue({ [detail.index]: value || undefined });
                    debouncedHandleDetailBlur(detail?.index);
                  }}
                  onBlur={() => {
                    debouncedHandleDetailBlur.cancel();
                    handleDetailBlur(detail?.index);
                  }}
                />
              </Form.Item>
            );

          case PROPERTY_TYPES.NUMERIC:
            return (
              <Form.Item
                name={detail?.index}
                label={detail?.name}
                rules={[
                  {
                    required: detail?.isRequired,
                    message: 'This field is required!',
                    validateTrigger: ['onChange', 'onBlur'],
                  },
                  number,
                  {
                    max: 15,
                    message: 'Maximum 15 digits are allowed',
                  },
                  { message: 'Please enter valid number!' },
                ]}
              >
                <Input
                  placeholder="Enter number"
                  onChange={(e) => {
                    const { value } = e.target;
                    form.setFieldsValue({ [detail.index]: value || undefined });
                    debouncedHandleDetailBlur(detail?.index);
                  }}
                  onBlur={() => {
                    debouncedHandleDetailBlur.cancel();
                    handleDetailBlur(detail?.index);
                  }}
                />
              </Form.Item>
            );

          case PROPERTY_TYPES.CHECKBOXES:
            return (
              <Form.Item
                name={detail?.index}
                label={detail?.name}
                rules={[
                  {
                    required: detail?.isRequired,
                    message: 'This field is required!',
                    validateTrigger: ['onChange', 'onBlur'],
                  },
                ]}
              >
                <CommonSelect
                  mode="multiple"
                  placeholder="Select Options"
                  options={options}
                  onChange={(value) => {
                    const newValue = value?.length > 0 ? value : undefined;
                    form.setFieldsValue({ [detail.index]: newValue });
                    handleDetailBlur(detail?.index);
                  }}
                />
              </Form.Item>
            );

          default:
            break;
        }
      }
    });
  };

  const renderPropertiesDetails = () => {
    return (
      <div>
        <Row gutter={15}>
          {map(propertyDetails, (property) => (
            <Col className="max-height-200" span={24}>
              <div
                className={`${isDesktopViewport ? 'width-300' : ''} d-flex justify-between mb-10`}
              >
                <EllipsisText text={property?.name} />
                <div className="d-flex">
                  <EditButton
                    className="mr-5"
                    onClick={() => handleEditProperty(property)}
                  />
                  <DeleteButton
                    className="ml-5"
                    onClick={() => handleDeleteProperty(property)}
                  />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    );
  };

  return (
    <div className="mb-20">
      <RouterPrompt openPrompt={state?.showPrompt} />
      <CommonCard className="checklist-details mb-24">
        <Row justify="space-between" align="middle">
          <Col span={isDesktopViewport ? 18 : 24}>
            <div
              className={`checklist-basic-details ${!isDesktopViewport && 'mb-10'}`}
            >
              {isEditable && (
                <Form layout="vertical" form={form}>
                  <Form.Item
                    name="registerName"
                    rules={[
                      required,
                      characterWithoutWhiteSpace,
                      {
                        max: 250,
                        message: 'Name cannot be more than 250 characters',
                      },
                    ]}
                    label="Register Name"
                  >
                    <Input
                      placeholder="Enter Register Name"
                      allowClear
                      className="width-200"
                      onBlur={async () => {
                        const value = form.getFieldValue('registerName');

                        if (registerData?.name === value) return;
                        if (registerId && value) {
                          await updateRegister({
                            variables: {
                              updateRegisterId: registerId,
                              data: {
                                name: value,
                              },
                            },
                          });
                        }
                      }}
                    />
                  </Form.Item>
                </Form>
              )}
              <h3>Details</h3>
              <Form
                form={form}
                layout="vertical"
                className="checklist-details-form d-flex"
              >
                {!createRegisterData &&
                  !isEditable &&
                  renderProperties(propertyDetails)}
                {/* this is for editable fields */}
              </Form>
              {isEditable && renderPropertiesDetails()}
              {/* this is for create register, update register and for displaying data */}
              {isEditable && (
                <Button
                  className="mt-25"
                  shape="round"
                  type="primary"
                  icon={<AddButton />}
                  onClick={handleAddProperty}
                >
                  Add Details
                </Button>
              )}
            </div>
          </Col>
          {createRegisterData ||
          registerData?.status === REGISTER_STATUS_LABEL.DRAFT ? (
            <Col
              className="flex-vertical d-flex justify-end"
              span={isDesktopViewport ? 6 : 24}
            >
              <div className="d-flex justify-end form-buttons">
                <CanPerform
                  action={ALLOWED_ACTION_KEYS.EDIT_PROJECT_REGISTER}
                  type={ALLOWED_ACTION_TYPE.BOTH}
                >
                  {/* Commented for future use */}
                  {/* <Button
                    shape="round"
                    className="cancel-button"
                    onClick={() => handleSaveAsDraft()}
                  >
                    Save as Draft
                  </Button> */}
                  <Button
                    shape="round"
                    type="primary"
                    icon={<SaveOutline />}
                    className={clsx(
                      !isDesktopViewport && 'width-percent-100 justify-center',
                      'edit-button-checklist d-flex',
                    )}
                    disabled={
                      !(
                        propertyDetails?.length > 0 &&
                        tableHeaders?.length > 0 &&
                        registerName
                      )
                    }
                    onClick={() => handleSave()}
                  >
                    Save
                  </Button>
                </CanPerform>
              </div>
            </Col>
          ) : null}
          {!createRegisterData && isEditable && (
            <Col
              className="flex-vertical d-flex justify-end"
              span={isDesktopViewport ? 6 : 24}
            >
              <div className="d-flex justify-end">
                <CanPerform
                  action={ALLOWED_ACTION_KEYS.EDIT_PROJECT_REGISTER}
                  type={ALLOWED_ACTION_TYPE.BOTH}
                >
                  <div className="form-buttons width-percent-100">
                    {registerData?.status === REGISTER_STATUS_LABEL.LIVE && (
                      <Button
                        shape="round"
                        icon={<ArchiveOutline className="mr-5" />}
                        className={clsx(
                          'delete-button-checklist d-flex',
                          !isDesktopViewport &&
                            'width-percent-100 justify-center',
                        )}
                        onClick={handleRegisterArchive}
                      >
                        Archive
                      </Button>
                    )}
                    {registerData?.status ===
                      REGISTER_STATUS_LABEL.ARCHIVED && (
                      <Button
                        shape="round"
                        type="primary"
                        icon={<InboxOutlined className="mr-5" />}
                        onClick={handleRegisterArchive}
                      >
                        Unarchive
                      </Button>
                    )}
                  </div>
                </CanPerform>
              </div>
            </Col>
          )}
        </Row>
      </CommonCard>
      {/* This is Table Component */}
      <CommonCard>
        <AddRegisterFieldsTable
          createRegisterData={createRegisterData}
          tableHeaders={tableHeaders}
          setTableHeaders={setTableHeaders}
          tableColumns={tableColumns}
          setTableColumns={setTableColumns}
          registerDetailsData={registerDetailsData}
          isEditable={isEditable}
        />
      </CommonCard>
      {showAddPropertyModal && (
        <AddEditPropertyModal
          createRegisterData={createRegisterData}
          showModal={showAddPropertyModal}
          setShowModal={setShowAddPropertyModal}
          propertyDetails={propertyDetails}
          setPropertyDetails={setPropertyDetails}
          setTableColumns={setTableColumns}
          editSelectedProperty={editSelectedProperty}
          setEditSelectedProperty={setEditSelectedProperty}
        />
      )}
      {showArchiveModal && (
        <ArchiveRegisterModal
          showModal={showArchiveModal}
          setShowModal={setShowArchiveModal}
          registerData={registerData}
        />
      )}
      <ConfirmationModal
        type={confirmationType}
        showModal={showModal}
        setShowModal={setShowModal}
        handleConfirmation={handleAction}
        setConfirmationType={setConfirmationType}
      />
      {showSaveModal && (
        <SaveRegisterLogModal
          handleRefetch={handleRegisterLogDetailRefetch}
          properties={properties}
          propertyDetails={propertyDetails}
          showModal={showSaveModal}
          setShowModal={setShowSaveModal}
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          isEditableProperty={isEditableProperty}
          setIsEditableProperty={setIsEditableProperty}
        />
      )}
      {showDeleteModal && (
        <DeletePropertyModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          deleteSelectedProperty={deleteSelectedProperty}
          setDeleteSelectedProperty={setDeleteSelectedProperty}
          setPropertyDetails={setPropertyDetails}
          setTableColumns={setTableColumns}
          createRegisterData={createRegisterData}
          propertyDetails={propertyDetails}
        />
      )}
    </div>
  );
};

export default RegisterPropertyDetails;
